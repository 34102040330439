import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab1 from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import axios from "axios";
import Card from "../../../components/Card";
import { MdOutlineDone } from "react-icons/md";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import user01 from "../../../assets/images/user/01.jpg";
import user02 from "../../../assets/images/user/02.jpg";
import user03 from "../../../assets/images/user/03.jpg";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Nav,
Tab,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Carousel as CarouselN,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/images/page-img/profile-bg1.jpg";
import user05 from "../../../assets/images/user/05.jpg";
import user06 from "../../../assets/images/user/06.jpg";
import user07 from "../../../assets/images/user/07.jpg";
import user08 from "../../../assets/images/user/08.jpg";
import user09 from "../../../assets/images/user/09.jpg";
import user10 from "../../../assets/images/user/10.jpg";
import g1 from "../../../assets/images/page-img/g1.jpg";
import g2 from "../../../assets/images/page-img/g2.jpg";
import g3 from "../../../assets/images/page-img/g3.jpg";
import g4 from "../../../assets/images/page-img/g4.jpg";
import g5 from "../../../assets/images/page-img/g5.jpg";
import g6 from "../../../assets/images/page-img/g6.jpg";
import g7 from "../../../assets/images/page-img/g7.jpg";
import g8 from "../../../assets/images/page-img/g8.jpg";
import g9 from "../../../assets/images/page-img/g9.jpg";
import img59 from "../../../assets/images/page-img/59.jpg";
import img51 from "../../../assets/images/page-img/51.jpg";
import img52 from "../../../assets/images/page-img/52.jpg";
import img53 from "../../../assets/images/page-img/53.jpg";
import img54 from "../../../assets/images/page-img/54.jpg";
import img55 from "../../../assets/images/page-img/55.jpg";
import img56 from "../../../assets/images/page-img/56.jpg";
import img57 from "../../../assets/images/page-img/57.jpg";
import img58 from "../../../assets/images/page-img/58.jpg";
// import img59 from '../../../assets/images/page-img/59.jpg'
import img60 from "../../../assets/images/page-img/60.jpg";
import img61 from "../../../assets/images/page-img/61.jpg";
import img62 from "../../../assets/images/page-img/62.jpg";
import img64 from "../../../assets/images/page-img/64.jpg";
import img65 from "../../../assets/images/page-img/65.jpg";
import img63 from "../../../assets/images/page-img/63.jpg";
import user15 from "../../../assets/images/user/15.jpg";
import user16 from "../../../assets/images/user/16.jpg";
import user17 from "../../../assets/images/user/17.jpg";
import user18 from "../../../assets/images/user/18.jpg";
import user19 from "../../../assets/images/user/19.jpg";
import EventTab from '../EventTab';
import Group from '../../dashboard/app/groups';
import Connections from '../app/Connections';
import CatergoryGrid from '../store/store-category-grid';
import AboutTab from './aboutTab';
import MarketTab from './marketTab';
import PhotoTab from './photoTab';
import { AiOutlineDelete } from "react-icons/ai";

const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({userUid}) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [userid, setUserid] = useState("");
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("user details fetched successfully user", response?.data?.data);
      setUserData(response?.data?.data);
      setUserid(response?.data?.data._id);
    } catch (error) {
      console.error("Failed Fetching Details", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userid]);
  //    const {userid} = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (postid) => {
    setDeleteID(postid);
     setShowDelete(true); 
  }
  const [post, setPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleAllPost = async (updateData = false) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/post/all?page=${page}&limit=5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Post Fetched Successfully", response.data.data.allPosts);
      // setPost(response.data.data.allPosts);
      // setHasMore(response.data.data.allPosts.length > 0);
      if (updateData) {
        setPost(response.data.data.allPosts);
      } else {
        setPost((prevPost) => [...prevPost, ...response.data.data.allPosts]);
      }
      setHasMore(response.data.data.noOfEntries > 0);
  
    } catch (error) {
      console.log("Failed Fetching Posts", error);
    }
  };

  useEffect(() => {
    if(page===1)
      {
        handleAllPost(true);
      }else{
        handleAllPost();
      }
  }, [page, userid]);
const [deleteID, setDeleteID] = useState("");

  const handleDeletePost = async () => {
    if(!deleteID){
      toast.info("Please try later");
    }
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/post/${deleteID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleAllPost(true);
      handleCloseDelete();
      setDeleteID("");
      console.log("Post Deleted Successfully");
      toast.success('Post deleted successfully');
    } catch (error) {
      console.error("Failed Fetching Details", error);
      toast.error('Failed to delete post');
    }
  };

  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpand = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };
  const renderDescription = (data, index) => {
    const description = data?.description;
    const threshold = 180;

    if (description && description.length > threshold) {
      if (expandedRows[index]) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "#FF5500",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "block",
              }}
            >
              Show Less...
            </button>
          </div>
        );
      } else {
        return (
          <div>
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: `${description.slice(0, threshold)}...`,
              }}
            />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "#FF5500",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "inline",
              }}
            >
              Show More...
            </button>
          </div>
        );
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: description }} />;
    }
  };
  const profileImage = localStorage.getItem("profileImage")
    ? localStorage.getItem("profileImage")
    : "/profile.png";

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  const [userDetail, setUserDetail] = useState("");
  const handleUserDetail = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/scan-profile/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("User Detail fetched successfully", response.data.data);
      setUserDetail(response.data.data);
    } catch (error) {
      console.log("Failed Fetching User Detail", error);
    }
  };

  useEffect(() => {
    handleUserDetail();
  }, [userid]);

  const handleSendRequest = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/connection/sent-request`,
        {
          requestTo: userid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);
      console.log("Request Send successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Failed Sending Friend Request", error);
    }
  };

  const handleSavePost = async (postID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/post/save/${postID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data"
          },
        }
      );
      console.log("Post Saved", response.data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed Saving Post");
      console.log("Failed Saving Posts", error);
    }
  };

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const [qrImageUrl, setQrImageUrl] = useState("");
  const handleQrClick = (imageUrl) => {
    setQrImageUrl(imageUrl);
    setQrPopupOpen(true);
  };

  const closeQrPopup = () => {
    setQrPopupOpen(false);
  };

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportDescription, setReportDescription] = useState("");
  const [reportId, setReportId] = useState("");
  const handleReportClick = (reqid) => {
    setReportId(reqid);
    setReportModalOpen(true);
  };

  const closeReportModal = () => {
    setReportModalOpen(false);
  };
  const handleReport = async () => {
    if (!reportDescription) {
      toast.info("Report issue is required");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/report`,
        {
          reportFor: "post",
          issueDescription: reportDescription,
          userId: "",
          postId: reportId,
          eventId: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      closeReportModal();
      setReportId("");
      setReportDescription("");
      console.log("Post Reported", response.data);
      toast.success(response.data.message);
    } catch (error) {
      setReportId("");
      setReportDescription("");
      toast.error(error?.response?.data?.message || "Failed Reporting Post");
      console.log("Failed SaviReportingng Posts", error);
    }
  };

  const [connection, setConnection] = useState([]);


  const handleConnections = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/connection/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Connections Fetched Successfully");
      setConnection(response.data.data);
    } catch (error) {
      console.log("Failed Fetching Posts", error);
    }
  };
  useEffect(() => {
    handleConnections();
  }, []);

  const handleNavigateProfile = (userid) => {
    navigate(`/userprofile/${userid}`);
  };
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileInputChange = (event) => {
    setSelectedFile([...event.target.files]);
    toast.success('File selected!')
  };
  const [categorypost, setCategorypost] = useState("public");

  const handleCategoryChange = (e) => {
    setCategorypost(e.target.value);
  };

  const [photos, setPhotos] = useState([]);
  const handlePost = async () => {

    if (!description) {
      toast.info("Description is required");
      return;
    }
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('files', selectedFile[i]);
      }

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/file/upload/multiple`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      console.log("oath", uploadResponse.data.data)

      setPhotos(uploadResponse.data.data)
      postPhoto(uploadResponse.data.data);


      //   setShow(false);
    } catch (error) {
      console.log("Failed to create post:", error);
      toast.error(error?.uploadResponse?.data?.message || "Failed to Upload Image/Video");
    }
  };


  const postPhoto = async (photoss) => {
    if (!description) {
      toast.info("Description is required");
      return;
    }
    try {
      const postData = {
        description: description,
        photos: photoss,
        postType: categorypost,
      };

      const postResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/post`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      handleAllPost(true);
      console.log("Post Successfully", postResponse.data);
      toast.success("Post created successfully");
      setDescription("");
      setSelectedFile([]);
      //   setShow(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Failed Posting ", error);
    } finally {
      setShow(false);
    }
  }
  const [userDetailSocial, setUserDetailSocial] = useState("");
  const handleUserDetailSocial = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/social-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("User Detail fetched successfully social", response.data.data.userData);
      let detail = response.data.data.userData
      setEditForm({
        userBio: detail?.userProfileData?.userBio,
        city: detail?.userProfileData?.address?.city,
        country: detail?.userProfileData?.address?.country,
        birthday: detail?.userProfileData?.birthday
      })
      setUserDetailSocial(detail);
    } catch (error) {
      console.log("Failed Fetching User Detail", error);
    }
  };

  useEffect(() => {
    handleUserDetailSocial();
  }, [value]);

  const [profileInfo, setProfileInfo] = useState(true);
  const [edit, setEdit] = useState(false);
  const [editForm, setEditForm] = useState({
    userBio: "",
    city: "",
    country: "",
    birthday: ""
  })
  const openEdit = () => {
    setProfileInfo(false);
    setEdit(true);
  }

  const handleSave = async () => {
    console.log(editForm)
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/user/social-profile`,
        editForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleUserDetailSocial()
      setProfileInfo(true);
      setEdit(false);
    } catch (error) {
      console.error("Failed Fetching Details", error);
    }
  }

  const handleEditFormChange = (e) => {
    setEditForm({ ...editForm, [e.target.name]: e.target.value })
  }

  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [ postidDetail, setPostidDetail] = useState("");
  const handleCloseGroupPopup = () => setShowGroupPopup(false);

  const handleShowGroupPopup = (postid) => {
      setPostidDetail(postid);
      setShowGroupPopup(true);}
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);

  const handleAllGroups = async () => {
    try {
      const token = localStorage.getItem("token");
      let url = `${process.env.REACT_APP_URL}/api/group/all?searchfor=my`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Group Fetched Successfully");
      setGroups(response.data.data);
      
    } catch (error) {
      console.log("Failed Fetching Group", error);
    }
  };

  useEffect(() => {
    handleAllGroups();
  }, []);

  const handleGroupSelection = (e) => {
    const value = e.target.value;
    setSelectedGroups(prev =>
      prev.includes(value) ? prev.filter(group => group !== value) : [...prev, value]
    );
  };

  const handleSubmit = async () => {
      if (!postidDetail) {
          toast.info("Please try later");
          return;
        }
      const payload = {
          postId: postidDetail,
        groupIds: selectedGroups,
      };
      const token = localStorage.getItem("token");
  
      try {
        const response = await axios.post('https://dirido-b2c.pb18.in/api/post/share', payload, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('Share response:', response.data);
        toast.success(response.data.message);
        setSelectedGroups([]);
        handleCloseGroupPopup();
      } catch (error) {
          toast.error("Error sharing post")
        console.log('Share request failed', error);
      }
      
    };
  
    const handleCopyUrl = (postId) => {
      const url = `https://dirido.com/post/${postId}`;
      navigator.clipboard.writeText(url).then(() => {
      //   alert('URL copied to clipboard');
      toast.success("URL Copied")
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    };

  return (
    <>
   
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
          scrollButtons="auto">
          <Tab1 label="Dirido QRC" {...a11yProps(0)} />
          <Tab1 label="Posts" {...a11yProps(1)} />
          <Tab1 label="Photos" {...a11yProps(2)} />
          <Tab1 label="About" {...a11yProps(2)} />
          <Tab1 label="Market Place" {...a11yProps(2)} />
          <Tab1 label="Connections" {...a11yProps(2)} />
          <Tab1 label="Groups" {...a11yProps(2)} />
          <Tab1 label="Events" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {/* QR Code */}
      <CatergoryGrid/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
      <Row>
                      <Col lg={4}>

                        <Card>
                          <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                              <h4 className="card-title">Profile Intro</h4>
                            </div>
                            {profileInfo && (
                              <div className="header-title">
                                <Link onClick={openEdit} className="material-symbols-outlined">
                                  edit
                                </Link>
                              </div>)}
                            {edit && (
                              <div className="header-title">
                                <button style={{ width: "100px" }}
                                  className="btn btn-primary d-block "
                                  onClick={handleSave}
                                >
                                  Save
                                </button>
                              </div>)}

                          </div>
                          <Card.Body>
                            <div className="ui-block-content">
                              {profileInfo && (
                                <ul className="list-unstyled ">
                                  {userDetailSocial?.userProfileData?.userBio || userDetailSocial?.userProfileData?.address?.city || userDetailSocial?.userProfileData?.address?.country || userDetailSocial?.userProfileData?.birthday ? (
                                    <div>
                                      <div className="text">
                                        <li className="mb-1">
                                          <h6 className="title">About Me:</h6>
                                          <span className="text">
                                            {userDetailSocial?.userProfileData?.userBio}
                                          </span>
                                        </li>
                                      </div>
                                      <div className="text">
                                        <li className="mb-1">
                                          <h6 className="title">City:</h6>
                                          <span className="text">
                                            {userDetailSocial?.userProfileData?.address?.city}
                                          </span>
                                        </li>
                                      </div>
                                      <div className="text">
                                        <li className="mb-1">
                                          <h6 className="title">Country:</h6>
                                          <span className="text">
                                            {userDetailSocial?.userProfileData?.address?.country}
                                          </span>
                                        </li>
                                      </div>
                                      <div className="text">
                                        <li className="mb-1">
                                          <h6 className="title">Birthday:</h6>
                                          <span className="text">
                                            {moment(userDetailSocial?.userProfileData?.birthday).format("DD-MM-YYYY")}
                                          </span>
                                        </li>
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="text">
                                      Please add profile info...
                                    </span>)}

                                </ul>
                              )}
                              {edit && (
                                <ul className="list-unstyled ">
                                  <span className="text">
                                    <li className="mb-4">
                                      {/* <h6 className="title">About Me:</h6> */}

                                      <Form.Label>
                                        User Bio {" "}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="userBio"
                                        className="mb-0 py-2"
                                        id=""
                                        placeholder="Enter bio.."
                                        value={editForm.userBio}
                                        onChange={(e) => { handleEditFormChange(e) }}
                                      />

                                      <Form.Label>
                                        City{" "}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="city"
                                        className="mb-0 py-2"
                                        id=""
                                        placeholder="Enter city.."
                                        value={editForm.city}
                                        onChange={(e) => { handleEditFormChange(e) }}
                                      />
                                      <Form.Label>
                                        Country{" "}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="country"
                                        className="mb-0 py-2"
                                        id=""
                                        placeholder="Enter country.."
                                        value={editForm.country}
                                        onChange={(e) => { handleEditFormChange(e) }}
                                      />
                                      <Form.Label>
                                        Birthday{" "}
                                      </Form.Label>
                                      <Form.Control
                                        type="date"
                                        name="birthday"
                                        className="mb-0 py-2"
                                        id=""
                                        placeholder="Enter birthday.."
                                        value={moment(editForm.birthday).format('YYYY-MM-DD')}
                                        onChange={(e) => { handleEditFormChange(e) }}
                                      />

                                    </li>
                                  </span>


                                </ul>
                              )}



                              {/* <li className="mb-4">
                                  <h6 className="title">Favourite TV Shows:</h6>
                                  <span className="text">
                                    Breaking Good, RedDevil, People of Interest,
                                    The Running Dead, Found, American Guy.
                                  </span>
                                </li> */}
                              {/* <li className="mb-4">
                                  <h6 className="title">
                                    Favourite Music Bands / Artists:
                                  </h6>
                                  <span className="text">
                                    Iron Maid, DC/AC, Megablow, The Ill, Kung
                                    Fighters, System of a Revenge.
                                  </span>
                                </li> */}
                              {/* </ul> */}
                              {/* <div className="widget w-socials">
                                <h6 className="title mb-3">
                                  Other Social Networks:
                                </h6>
                                <Link
                                  to="#"
                                  className="social-item btn btn-primary d-flex align-items-center justify-content-center"
                                >
                                  <svg
                                    className="me-1"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                  >
                                    <title>facebook1</title>
                                    <path d="M17 3v2h-2c-0.552 0-1.053 0.225-1.414 0.586s-0.586 0.862-0.586 1.414v3c0 0.552 0.448 1 1 1h2.719l-0.5 2h-2.219c-0.552 0-1 0.448-1 1v7h-2v-7c0-0.552-0.448-1-1-1h-2v-2h2c0.552 0 1-0.448 1-1v-3c0-1.105 0.447-2.103 1.172-2.828s1.723-1.172 2.828-1.172zM18 1h-3c-1.657 0-3.158 0.673-4.243 1.757s-1.757 2.586-1.757 4.243v2h-2c-0.552 0-1 0.448-1 1v4c0 0.552 0.448 1 1 1h2v7c0 0.552 0.448 1 1 1h4c0.552 0 1-0.448 1-1v-7h2c0.466 0 0.858-0.319 0.97-0.757l1-4c0.134-0.536-0.192-1.079-0.728-1.213-0.083-0.021-0.167-0.031-0.242-0.030h-3v-2h3c0.552 0 1-0.448 1-1v-4c0-0.552-0.448-1-1-1z"></path>
                                  </svg>
                                  Facebook
                                </Link>
                                <Link
                                  to="#"
                                  className="social-item btn btn-info d-flex align-items-center justify-content-center"
                                >
                                  <svg
                                    className="me-1"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                  >
                                    <title>twitter</title>
                                    <path d="M20.833 5.262c-0.186 0.242-0.391 0.475-0.616 0.696-0.233 0.232-0.347 0.567-0.278 0.908 0.037 0.182 0.060 0.404 0.061 0.634 0 5.256-2.429 8.971-5.81 10.898-2.647 1.509-5.938 1.955-9.222 1.12 1.245-0.361 2.46-0.921 3.593-1.69 0.147-0.099 0.273-0.243 0.352-0.421 0.224-0.505-0.003-1.096-0.508-1.32-2.774-1.233-4.13-2.931-4.769-4.593-0.417-1.084-0.546-2.198-0.52-3.227 0.021-0.811 0.138-1.56 0.278-2.182 0.394 0.343 0.803 0.706 1.235 1.038 2.051 1.577 4.624 2.479 7.395 2.407 0.543-0.015 0.976-0.457 0.976-1v-1.011c-0.002-0.179 0.009-0.357 0.034-0.533 0.113-0.806 0.504-1.569 1.162-2.141 0.725-0.631 1.636-0.908 2.526-0.846s1.753 0.463 2.384 1.188c0.252 0.286 0.649 0.416 1.033 0.304 0.231-0.067 0.463-0.143 0.695-0.228zM22.424 2.183c-0.74 0.522-1.523 0.926-2.287 1.205-0.931-0.836-2.091-1.302-3.276-1.385-1.398-0.097-2.836 0.339-3.977 1.332-1.036 0.901-1.652 2.108-1.83 3.372-0.037 0.265-0.055 0.532-0.054 0.8-1.922-0.142-3.693-0.85-5.15-1.97-0.775-0.596-1.462-1.309-2.034-2.116-0.32-0.45-0.944-0.557-1.394-0.237-0.154 0.109-0.267 0.253-0.335 0.409 0 0-0.132 0.299-0.285 0.76-0.112 0.337-0.241 0.775-0.357 1.29-0.163 0.722-0.302 1.602-0.326 2.571-0.031 1.227 0.12 2.612 0.652 3.996 0.683 1.775 1.966 3.478 4.147 4.823-1.569 0.726-3.245 1.039-4.873 0.967-0.552-0.024-1.019 0.403-1.043 0.955-0.017 0.389 0.19 0.736 0.513 0.918 4.905 2.725 10.426 2.678 14.666 0.261 4.040-2.301 6.819-6.7 6.819-12.634-0.001-0.167-0.008-0.33-0.023-0.489 1.006-1.115 1.676-2.429 1.996-3.781 0.127-0.537-0.206-1.076-0.743-1.203-0.29-0.069-0.58-0.003-0.807 0.156z"></path>
                                  </svg>
                                  Twitter
                                </Link>
                                <Link
                                  to="#"
                                  className="social-item btn btn-danger d-flex align-items-center justify-content-center"
                                >
                                  <svg
                                    className="me-1"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <title>dribbble</title>
                                    <path d="M8 16c-4.412 0-8-3.588-8-8s3.587-8 8-8c4.412 0 8 3.587 8 8s-3.588 8-8 8v0zM14.747 9.094c-0.234-0.075-2.116-0.634-4.256-0.291 0.894 2.456 1.256 4.456 1.328 4.872 1.531-1.037 2.625-2.678 2.928-4.581v0zM10.669 14.3c-0.103-0.6-0.497-2.688-1.456-5.181-0.016 0.006-0.031 0.009-0.044 0.016-3.856 1.344-5.241 4.016-5.362 4.266 1.159 0.903 2.616 1.444 4.194 1.444 0.947 0 1.85-0.194 2.669-0.544v0zM2.922 12.578c0.156-0.266 2.031-3.369 5.553-4.509 0.088-0.028 0.178-0.056 0.269-0.081-0.172-0.388-0.359-0.778-0.553-1.159-3.409 1.022-6.722 0.978-7.022 0.975-0.003 0.069-0.003 0.138-0.003 0.209 0 1.753 0.666 3.356 1.756 4.566v0zM1.313 6.609c0.306 0.003 3.122 0.016 6.319-0.831-1.131-2.013-2.353-3.706-2.534-3.953-1.913 0.903-3.344 2.666-3.784 4.784v0zM6.4 1.366c0.188 0.253 1.431 1.944 2.55 4 2.431-0.909 3.459-2.294 3.581-2.469-1.206-1.072-2.794-1.722-4.531-1.722-0.55 0.003-1.088 0.069-1.6 0.191v0zM13.291 3.691c-0.144 0.194-1.291 1.663-3.816 2.694 0.159 0.325 0.313 0.656 0.453 0.991 0.050 0.119 0.1 0.234 0.147 0.353 2.275-0.284 4.534 0.172 4.759 0.219-0.016-1.612-0.594-3.094-1.544-4.256v0z"></path>
                                  </svg>
                                  Dribbble
                                </Link>
                              </div> */}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={8}>
                        <Col sm={12} >
                          <Card id="post-modal-data" className="card-block card-stretch card-height">
                            <div className="card-header d-flex justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Create Post</h4>
                              </div>
                            </div>
                            <Card.Body >
                              <div className="d-flex align-items-center">
                                <div className="user-img">
                                  <img src={userDetail?.profileImageLink
                                    ? userDetail?.profileImageLink
                                    : "/profile.png"} alt="user1" className="avatar-60 rounded-circle" />
                                </div>
                                <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                  <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} />
                                </form>
                              </div>
                              <hr></hr>
                            </Card.Body >
                            <Modal size="lg" className="fade" id="post-modal" onHide={handleClose} show={show} style={{ marginTop: "100px", maxHeight: "80vh" }}>
                              <Modal.Header className="d-flex justify-content-between">
                                <Modal.Title id="post-modalLabel">Create Post</Modal.Title>
                                <Link to="#" className="lh-1" onClick={handleClose} >
                                  <span className="material-symbols-outlined">close</span>
                                </Link>
                              </Modal.Header>
                              <Modal.Body >
                                <div className="d-flex align-items-center">
                                  <div className="user-img">
                                    <img src={profileImage} alt="user1" className="avatar-60 rounded-circle" />
                                  </div>
                                  <form className="post-text ms-3 w-100 " onClick={handleShow}>
                                    <input type="text" className="form-control rounded" placeholder="Write something here..." style={{ border: "none" }} value={description}
                                      onChange={(e) => setDescription(e.target.value)} />
                                  </form>
                                </div>
                                <hr></hr>
                                <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0 ">
                                  {/* <li className="col-md-12 mb-2" >
                                                    <Form.Label>
                                                        Description{" "}
                                                        <span style={{ color: "red" }}>*</span>{" "}
                                                    </Form.Label>
                                                    
                                                    <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mb-0 py-2"
                            id=""
                            placeholder="Enter description"
                            required
                          />
                                                </li> */}

                                  <li className="col-md-12 mb-2" >
                                    {/* <div className="bg-soft-primary rounded p-2 pointer me-3" onClick={handlePhotoVideoClick}>
                                                <Link to="#">Photo/Video</Link>
                                            </div> */}
                                    {/* <input  className="bg-soft-primary rounded p-2 pointer me-3"
                                                type="file"
                                                multiple
                                                style={{width:"90%"}}
                                                onChange={handleFileInputChange}
                                                /> */}
                                    {/* <label htmlFor="file-input" className="bg-soft-primary rounded p-2 pointer" style={{ width: "90%", textAlign:"center" }}>
                                                    Select File
                                                    <input
                                                        id="file-input"
                                                        type="file"
                                                        multiple
                                                        style={{ display: "none" }}
                                                        onChange={handleFileInputChange}
                                                    />
                                                </label> */}
                                    <Form.Label>
                                      Image{" "}
                                      <span style={{ color: "red" }}>*</span>{" "}
                                    </Form.Label>
                                    <label
                                      htmlFor="file-input"
                                      style={{ cursor: "pointer", width: "100%" }}
                                    >
                                      <div
                                        className="bg-soft-primary rounded p-1 pointer"
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                          fontSize: "1.2rem",
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <input
                                          id="file-input"
                                          type="file"
                                          name="image"
                                          multiple
                                          style={{ display: "none" }}
                                          onChange={handleFileInputChange}
                                        />
                                        <span>
                                          {
                                            selectedFile.length > 0 ?
                                              <MdOutlineDone style={{ fontSize: '25px' }} />
                                              :
                                              <i className="fas fa-upload"></i>
                                          }
                                        </span>
                                        <p style={{ marginTop: ".5rem" }}>
                                          Click to Select Files
                                        </p>
                                      </div>
                                    </label>


                                  </li >
                                  <li className="col-md-12 mt-1">
                                    <Form.Group className="form-group">
                                      <Form.Label>
                                        Select a Category{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Form.Label>
                                      <div className="mb-0 ">
                                        <Form.Check
                                          style={{ marginRight: "20px" }}
                                          type="radio"
                                          name="category"
                                          value="public"
                                          label="Public"
                                          checked={categorypost === "public"}
                                          onChange={handleCategoryChange}
                                          required
                                        />
                                        <Form.Check
                                          type="radio"
                                          name="category"
                                          value="private"
                                          label="Connection"
                                          checked={categorypost === "private"}
                                          onChange={handleCategoryChange}
                                          required
                                        />
                                      </div>
                                    </Form.Group>
                                  </li>

                                </ul >
                                {/* <hr /> */}

                                < button type="submit" className="btn btn-primary d-block w-100 mt-0" onClick={handlePost} > Post</button >
                              </Modal.Body >
                            </Modal >
                            <Modal
      size="md"
      className="fade"
      id="post-modal"
      onHide={handleCloseDelete}
      show={showDelete}
      centered
      style={{ marginTop: "100px", maxHeight: "80vh" }}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="post-modalLabel">Delete Post</Modal.Title>
        <Link to="#" className="lh-1" onClick={handleCloseDelete}>
          <span className="material-symbols-outlined">close</span>
        </Link>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <p>Are you sure you want to delete this post? This action cannot be undone.</p>
        </div>
        <Button variant="danger" className="d-block w-100 mt-3" onClick={handleDeletePost}>
          Delete
        </Button>
      </Modal.Body>
    </Modal>
    <Modal size="md" className="fade" onHide={handleCloseGroupPopup} show={showGroupPopup} centered style={{maxHeight:"70vh", marginTop:"100px"}}>
        <Modal.Header closeButton style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 10 }}>
          <Modal.Title>Select Groups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {groups.length === 0 ? (
      <div>No groups found.</div>
    ) : (
          <Form.Group>
            <Form.Label>Select Groups to Share</Form.Label>
            <div className="d-flex flex-column">
              {groups.map((group, index) => (
                <div
                  key={index}
                  style={{
                    padding: '10px',
                    margin: '5px 0',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: selectedGroups.includes(group._id) ? '#d1e7dd' : '#f8f9fa',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    setSelectedGroups(prev =>
                      prev.includes(group._id) ? prev.filter(g => g !== group._id) : [...prev, group._id]
                    );
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    // label={group.groupName}
                    value={group._id}
                    checked={selectedGroups.includes(group._id)}
                    onChange={handleGroupSelection}
                    style={{ marginRight: '10px' }}
                  />
                  <span>{group.groupName}</span>
                </div>
              ))}
            </div>
          </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer style={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 10 }}>
          <Button variant="secondary" onClick={handleCloseGroupPopup}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>

                          </Card >
                        </Col >
                        <InfiniteScroll
                          dataLength={post.length}
                          next={() => setPage((page) => page + 1)}
                          hasMore={page<=hasMore}
                          loader={
                            <div className="col-sm-12 text-center">
                              <img
                                src={loader}
                                alt="loader"
                                style={{ height: "100px" }}
                              />
                            </div>
                          }
                          endMessage={
                            <p className="text-center">No posts available</p>
                          }
                        >
                          {post?.map((data, index) => (
                            <Col lg={12}>
                              <Card>
                                <Card.Body>
                                  <div className="post-item">
                                    <div className="user-post-data p-3">
                                      <div className="d-flex justify-content-between">
                                        <div className="me-3">
                                          <img
                                            className="rounded-circle img-fluid"
                                            src={
                                              data?.userId?.profileImageLink
                                                ? data?.userId?.profileImageLink
                                                : "/profile.png"
                                            }
                                            alt=""
                                            style={{
                                              width: "55px",
                                              height: "50px",
                                            }}
                                          />
                                        </div>
                                        <div className="w-100">
                                          <div className="d-flex justify-content-between flex-wrap">
                                            <div>
                                              <h5 className="mb-0 d-inline-block">
                                                {data?.userId?.firstName}{" "}
                                                {data?.userId?.lastName}
                                              </h5>

                                              <p
                                                className="mb-0 "
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {moment(
                                                  data?.createdAt
                                                ).fromNow()}
                                              </p>
                                            </div>
                                            <div
                                              className="card-post-toolbar"
                                              style={{
                                                display: "flex",
                                                gap: "30px",
                                              }}
                                            >
                                              {data?.userId?.qrCodeUrl ? (
                                                <div>
                                                  <img
                                                    src={
                                                      data?.userId?.qrCodeUrl
                                                    }
                                                    onClick={() =>
                                                      handleQrClick(
                                                        data?.userId?.qrCodeUrl
                                                      )
                                                    }
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                <div></div>
                                              )}

                                              <Dropdown>
                                                <Dropdown.Toggle variant="bg-transparent">
                                                  <span className="material-symbols-outlined">
                                                    more_horiz
                                                  </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                  <Dropdown.Item className=" p-3" onClick={()=>handleShowDelete(data?._id)}>
                                                    <div className="d-flex align-items-top" >
                                                    <AiOutlineDelete size={22} style={{color:"#3f414d"}}/>

                                                      <div className="data ms-2">
                                                        <h6>Delete Post</h6>
                                                        <p className="mb-0">Delete this post from your feed</p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  {/* <Dropdown.Item className=" p-3" to="#">
                                                    <div className="d-flex align-items-top">
                                                    <AiOutlineDelete size={22}/>

                                                      <div className="data ms-2">
                                                        <h6>Delete Post</h6>
                                                        <p className="mb-0">Delete this post from your feed</p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item> */}
                                                  <Dropdown.Item className=" p-3" to="#">
                                                    <div className="d-flex align-items-top" onClick={()=>handleShowGroupPopup(data?._id)}>
                                                      <i className="ri-notification-line h4"></i>
                                                      <div className="data ms-2">
                                                        <h6>Share to Group</h6>
                                                        <p className="mb-0">Spread this post to your groups</p>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item className="p-3" to="#" onClick={() => handleCopyUrl(data?._id)}>
          <div className="d-flex align-items-top">
            <i className="ri-link h4"></i>
            <div className="data ms-2">
              <h6>Copy URL</h6>
              <p className="mb-0">Copy post URL to clipboard</p>
            </div>
          </div>
        </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              <Modal
                                                size="md"
                                                className="fade"
                                                id="qr-modal"
                                                onHide={closeQrPopup}
                                                show={qrPopupOpen}
                                                centered
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    QR Code
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="text-center">
                                                  <img
                                                    src={qrImageUrl}
                                                    alt="QR Code"
                                                    style={{
                                                      maxWidth: "100%",
                                                      height: "auto",
                                                    }}
                                                  />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <button
                                                    className="btn btn-secondary"
                                                    onClick={closeQrPopup}
                                                  >
                                                    Close
                                                  </button>
                                                </Modal.Footer>
                                              </Modal>
                                              <Modal
                                                size="md"
                                                className="fade"
                                                id="report-modal"
                                                onHide={closeReportModal}
                                                show={reportModalOpen}
                                                centered
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    Report Post
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body
                                                  style={{
                                                    maxHeight: "450px",
                                                    overflowY: "auto",
                                                  }}
                                                >
                                                  <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0 ">
                                                    <li className="col-12 ">
                                                      <Form.Group className="form-group">
                                                        <Form.Label>
                                                          What are you trying to
                                                          report?
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          value={
                                                            reportDescription
                                                          }
                                                          onChange={(e) =>
                                                            setReportDescription(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="mb-0 py-2"
                                                          id=""
                                                          placeholder="Report issue..."
                                                          required
                                                        />
                                                      </Form.Group>
                                                    </li>
                                                  </ul>

                                                  <button
                                                    type="submit"
                                                    className="btn btn-primary d-block w-100 mt-3"
                                                    onClick={handleReport}
                                                  >
                                                    Report
                                                  </button>
                                                </Modal.Body>
                                              </Modal>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-3">
                                      <p>{renderDescription(data, index)}</p>
                                      {/* <p>{data?.description}</p> */}
                                    </div>
                                    <div className="user-post">
                                      <CarouselN
                                        style={{
                                          maxWidth: "600px",
                                          margin: "0 auto",
                                        }}
                                      >
                                        {data?.photos?.map(
                                          (photo, photoIndex) => (
                                            <CarouselN.Item key={photoIndex}>
                                              <img
                                                className="d-block w-100"
                                                src={photo}
                                                alt={`post-photo-${photoIndex}`}
                                                style={{
                                                  width: "100%",
                                                  height: "auto",
                                                  objectFit: "cover",
                                                  borderRadius: "8px",
                                                }}
                                              />
                                            </CarouselN.Item>
                                          )
                                        )}
                                      </CarouselN>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </InfiniteScroll>
                      </Col >
                    </Row >
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
      {/* Photos */}
      <PhotoTab userUid={userUid}/>     
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        {/* About */}
      <Card className="p-2">
        <AboutTab/>     
      </Card>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
      {/* Market Place */}
      <Card className="p-2">
        <MarketTab/>     
      </Card>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <Connections/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <Group/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={7}>
        <EventTab/>
      </CustomTabPanel>
    </Box>
    </>
  );
}
