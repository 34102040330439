import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import { Container, Row, Col, Nav, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import store1 from "../../../assets/images/store/01.jpg";
import store2 from "../../../assets/images/store/02.jpg";
import store3 from "../../../assets/images/store/03.jpg";
import store4 from "../../../assets/images/store/04.jpg";
import store5 from "../../../assets/images/store/05.jpg";
import store6 from "../../../assets/images/store/06.jpg";
import store7 from "../../../assets/images/store/07.jpg";
import store8 from "../../../assets/images/store/08.jpg";
import imgn56 from "../../../assets/images/page-img/56.jpg";
import imgp11 from "../../../assets/images/user/11.png";
import imgp08 from "../../../assets/images/icon/08.png";
import imgp09 from "../../../assets/images/icon/09.png";
import imgp10 from "../../../assets/images/icon/10.png";
import imgp13 from "../../../assets/images/icon/13.png";
import imgn51 from "../../../assets/images/page-img/51.jpg";
import imgn59 from "../../../assets/images/page-img/59.jpg";
import imgn55 from "../../../assets/images/page-img/55.jpg";
import imgn54 from "../../../assets/images/page-img/54.jpg";
import imgn53 from "../../../assets/images/page-img/53.jpg";
import imgn57 from "../../../assets/images/page-img/57.jpg";
import imgn58 from "../../../assets/images/page-img/58.jpg";
import imgn60 from "../../../assets/images/page-img/60.jpg";
import imgn61 from "../../../assets/images/page-img/61.jpg";
import imgn62 from "../../../assets/images/page-img/62.jpg";
import imgn64 from "../../../assets/images/page-img/64.jpg";
import imgn02 from "../../../assets/images/user/02.jpg";
import imgn03 from "../../../assets/images/user/03.jpg";
import qr1 from "../../../assets/QR1/qr1.png";
import qr2 from "../../../assets/QR1/qr2.png";
import qr3 from "../../../assets/QR1/qr3.png";
import qr4 from "../../../assets/QR1/qr4.png";
import axios from "axios";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useAuth from "../../../utils/useAuth";
import "./Style.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const CatergoryList = () => {
  useAuth();
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const [sponsoredAd, setSponsoredAd] = useState([]);
  const handleSponsored = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/ad/all/ads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data"
          },
        }
      );
      console.log(
        "Sponsored Ads Fetched Successfully",
        response.data.data.adData
      );
      setSponsoredAd(response.data.data.adData);
    } catch (error) {
      console.log("Failed Fetching Sponsored Ads", error);
    }
  };

  useEffect(() => {
    handleSponsored();
  }, []);
  const [expandedRows, setExpandedRows] = useState({});

  const [expandedPosts, setExpandedPosts] = useState(
    Array(sponsoredAd.length).fill(false)
  );

  const toggleExpanded = (index) => {
    const updatedExpandedPosts = [...expandedPosts];
    updatedExpandedPosts[index] = !updatedExpandedPosts[index];
    setExpandedPosts(updatedExpandedPosts);
  };
  const toggleExpand = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const renderDescription = (data, index) => {
    const description = data?.description;
    const threshold = 180;

    if (description && description.length > threshold) {
      if (expandedRows[index]) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "rgb(47,178,255)",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "block",
              }}
            >
              Show Less
            </button>
          </div>
        );
      } else {
        return (
          <div>
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: `${description.slice(0, threshold)}...`,
              }}
            />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "rgb(47,178,255)",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "inline",
              }}
            >
              Show More
            </button>
          </div>
        );
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: description }} />;
    }
  };

  return (
    <>
      {/* <ProfileHeader title="Store Category" img={profilebg7}/> */}
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Row>
                <Col md="12">
                  {/* <Card className="card-block card-stretch card-height product">
                            hello
                            </Card> */}
                  <Card className="card-block card-stretch card-height product">
                    <Card.Body>
                      {sponsoredAd
                        ?.filter((ad) => ad.status === "Active")
                        .map((data, index) => (
                          <div className="constinerinn">
                            <div className="container_ads">

                              <div className="image_ad">
                                {/* <img src={qr1} alt className="imageinside"/> */}
                                <div
                                  className="image-block position-relative imageinside"
                                  style={{ height: "100px", width: "100px" }}
                                >
                                  <Carousel
                                    showArrows={false}
                                    showThumbs={false}
                                    showStatus={false}
                                    infiniteLoop
                                    autoPlay
                                    interval={2000}
                                  >
                                    {data?.image?.length > 0 ? (
                                      data.image.map((image, imgIndex) => (
                                        <img
                                          key={imgIndex}
                                          src={image}
                                          className="img-fluid w-100 rounded"
                                          alt="product-img"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      ))
                                    ) : (
                                      <img
                                        src="/profile.png"
                                        className="img-fluid w-100 rounded"
                                        alt="default-img"
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </Carousel>
                                </div>
                                <h6
                                  style={{
                                    marginBlock: "10px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data?.title ? data?.title : "Title"}
                                </h6>
                              </div>
                              <div className="middle_ad">
                                <h1 style={{ fontWeight: "500" }}>
                                  Half Price Store
                                </h1>
                                <h6>
                                  Half Price Store: Flat 50% Off On Top Styles
                                </h6>
                              </div>
                              <div className="button_ad">
                                <button
                                  className="btn btn-primary d-block"
                                  style={{
                                    fontSize: "16px",
                                    paddingInline: "30px",
                                  }}
                                >
                                  Get Deal
                                </button>
                              </div>

                            </div>

                            {expandedPosts[index] && (
                              <>
                                <div className="mt-1">
                                  <ul>
                                    <li>Get flat 50% off on everything displayed on the landing page.</li>
                                    <li>Shop from a wide range of t-shirts, tops, kurtas, sarees, heels, sunglasses, bed covers, body lotion, hair appliances etc.</li>
                                    <li>No coupon code is required.</li>
                                  </ul>
                                </div>

                              </>
                            )}
                            <div
                              onClick={() => toggleExpanded(index)}
                              className="text-primary"
                              style={{
                                display: "flex",
                                border: "2px solid red",
                                border: "none",
                                borderRadius: "10px",
                                textAlign: "center",
                                justifyContent: "center",
                                padding: "0px 5px 10px 5px",
                              }}
                            >
                              {expandedPosts[index] ? (
                                <div>
                                  Hide Details <IoIosArrowUp />
                                </div>
                              ) : (
                                <div>
                                  View Details <IoIosArrowDown />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                      {/* {sponsoredAd?.filter(ad => ad.status === 'Active').map((data, index) => (
                                <Row
                                  className="align-items-top mb-3"
                                  key={index}
                                >
                                  <Col lg="6">
                                    <div
                                      className="image-block position-relative"
                                      style={{ height: "250px" }}
                                    >
                                      <Carousel
                                        showArrows={false}
                                        showThumbs={false}
                                        showStatus={false}
                                        infiniteLoop
                                        autoPlay
                                        interval={2000}
                                      >
                                        {data?.image?.length > 0 ? (
                                          data.image.map((image, imgIndex) => (
                                            <img
                                              key={imgIndex}
                                              src={image}
                                              className="img-fluid w-100 rounded"
                                              alt="product-img"
                                              style={{
                                                height: "250px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          ))
                                        ) : (
                                          <img
                                            src="/profile.png"
                                            className="img-fluid w-100 rounded"
                                            alt="default-img"
                                            style={{
                                              height: "250px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        )}
                                      </Carousel>
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="product-description ps-2 circle-category mt-2 mt-md-0">
                                      <div className="d-flex align-items-center justify-content-between mb-3 border-bottom pb-3">
                                        <div className="d-flex align-items-center">
                                          <h2>
                                            <Link to="#">
                                              {data?.title
                                                ? data?.title
                                                : "Title"}
                                            </Link>
                                          </h2>
                                        </div>
                                      </div>
                                      {data?.startDate && (
                                        <p className="mb-0 text-primary">
                                          Start:{" "}
                                          <span style={{ color: "#000" }}>
                                            {moment(data.startDate).format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )}
                                          </span>
                                        </p>
                                      )}
                                      {data?.endDate && (
                                        <p className="mb-0 text-primary">
                                          End:{" "}
                                          <span style={{ color: "#000" }}>
                                            {moment(data.endDate).format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )}
                                          </span>
                                        </p>
                                      )}
                                      {data?.validity && (
                                        <p className="mb-0 text-primary">
                                          Validity:{" "}
                                          <span style={{ color: "#000" }}>
                                            {data.validity}
                                          </span>
                                        </p>
                                      )}
                                      {data?.country && (
                                        <p className="mb-0 text-primary">
                                          Country:{" "}
                                          <span
                                            style={{
                                              color: "#000",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data.country}
                                          </span>
                                        </p>
                                      )}
                                      {data?.status && (
                                        <p className="mb-0 text-primary">
                                          Status:{" "}
                                          <span
                                            style={{
                                              color: "#000",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data.status}
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              ))} */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CatergoryList;
