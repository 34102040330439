import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../auth/Style.css';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import user1 from '../../../../assets/images/page-img/1.jpg'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import CarouselN from 'react-bootstrap/Carousel';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const ScannedQrcDetailsLostFound = () => {
   let navigate = useNavigate()
   const params = useParams();
   const [qrc, setQrc] = useState(null);
   const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
   const [error, setError] = useState(null);

   const getLocation = () => {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
            (position) => {
               setUserLocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
               });
               setError(null);
            },
            (error) => {
               setError(error.message);
            }
         );
      } else {
         setError('Geolocation is not supported by this browser.');
      }
   };

   const splitFileLink = (a) => {
      const x = a.split('_');
      const y = x[x.length - 1];
      return decodeURIComponent(y);
   }

   const handleGenerateQrc = async () => {
      // e.preventDefault();
      const dataToSent = {
         "location": userLocation,
         "device": ""
      }
      try {
         const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/qrc/scan/${params?.qrcid}`, dataToSent,

         );
         // toast.success(response?.data?.message);
         setQrc(response?.data?.data?.qrcContentData);

      } catch (error) {
         toast.error(error?.response?.data?.message);
      }
   }
   useEffect(() => {
     getLocation();
      // if(userLocation?.latitude){
         handleGenerateQrc();
      // }
   }, [!userLocation?.latitude])
   // useEffect(() => {
   //    if (qrc?.qrcContentId?.userDeviceId?.isLost == false && qrc?.destinationUrl) {
   //       window.location.replace(qrc?.destinationUrl)
   //    }
   // }, [qrc])

   return (
      <>
              <ToastContainer/>

         <section className="d-flex justify-content-center " >

            <Container className="p-0" style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
               {/* <div style={{ fontSize: "30px", letterSpacing: "4px", color: "white", fontWeight: "600" }} className={window.innerWidth <= 768 ? "mobile-margin-top" : ""}>DIRIDO</div> */}
               <Row className="no-gutters w-100 px-1" style={{ borderRadius: "10px", justifyContent: "center", display: "flex", margin: "20px" }}>

                  {/* <div>
                     <button onClick={getLocation}>Get Location</button>
                     {location.latitude && location.longitude ? (
                        <div>
                           <h2>Your Location:</h2>
                           <p>Latitude: {location.latitude}</p>
                           <p>Longitude: {location.longitude}</p>
                        </div>
                     ) : (
                        error && <p>Error: {error}</p>
                     )}
                  </div> */}
                  {
                     qrc?.qrcContentId?.userDeviceId?.rewardAmount ?
                        <Col md="12" className=" d-flex flex-column justify-content-around " style={{ height: "100vh" }}>
                           <div>
                              <div className='text-center'><img src={qrc?.qrcContentId?.displayPhotoLink ? qrc?.qrcContentId?.displayPhotoLink : '/profile.png'} alt='img' className='rounded-circle' style={{ height: "60px", width: '60px' }} /></div>
                              <div className='d-flex flex-column justify-content-between align-items-center mt-1'>

                                 <div className='mt-3 d-flex justify-content-center flex-column align-items-center'>
                                    <b style={{ fontSize: "15px" }}>You’ve scanned for </b>
                                    <b style={{ fontSize: "20px" }}>{qrc?.qrcContentId?.lostAndFoundContent?.displayName}</b>
                                 </div>
                                 <div className='text-danger p-1 px-4' style={{
                                    fontSize: "18px",
                                    border: '2px solid #e9e9e9',
                                    fontSize: '18px',
                                    borderRadius: '120px ',
                                 }}
                                 >{qrc?.qrcContentId?.userDeviceId?.itemName}</div>

                                 <div className='mt-2 text-center'>
                                    <h4><b>Returning this item to get a
                                       reward of ${qrc?.qrcContentId?.userDeviceId?.rewardAmount} </b></h4>

                                 </div>
                                 <CarouselN style={{ maxWidth: '600px', margin: '0 auto' }}>
                                    {qrc?.qrcContentId?.userDeviceId?.devicePhotos?.map((photo, photoIndex) => (
                                       <CarouselN.Item key={photoIndex}>
                                          <img
                                             className="d-block w-100"
                                             src={photo}
                                             alt={`post-photo-${photoIndex}`}
                                             style={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                             }}
                                          />

                                       </CarouselN.Item>
                                    ))}
                                 </CarouselN>

                                 {/* <div className='mt-2 text-center w-100'>
                                    <h4><b>Device Images</b></h4>
                                    {
                                       qrc?.qrcContentId?.userDeviceId?.devicePhotos?.map((img) =>
                                          <div className='d-flex flex-wrap gap-3 justify-content-center mt-1'>
                                             <img src={img} alt='device image' width={100} height={100} className='rounded' />

                                          </div>
                                       )
                                    }
                                 </div> */}
                              </div>
                           </div>
                           <div className='d-flex flex-column gap-4 w-100'>
                              <Link to={`/lost-qrc-add-details/${qrc?.qrcContentId?.userDeviceId?._id}`} className='text-white'>  <Button className='w-100'>Continue</Button></Link>
                           </div>

                        </Col>
                        :
                        <Col md="12" className=" d-flex flex-column justify-content-center align-items-center " style={{ height: "100vh" }}>
                           <div>
                              <h3>Please add Data!</h3>
                           </div>
                        </Col>
                  }
               </Row>
            </Container>
         </section >
         <ToastContainer />
      </>
   )
}

export default ScannedQrcDetailsLostFound
