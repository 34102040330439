import React from "react";

// auth
import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import LockScreen from "../views/dashboard/auth/lock-screen";
import Recoverpw from "../views/dashboard/auth/recoverpw";
import SignIn from "../views/dashboard/auth/sign-in";
import Forgot from "../views/dashboard/auth/forgot";
import ForgotPassword from "../views/dashboard/auth/forgotpassword";
import SignUp from "../views/dashboard/auth/sign-up";

// errors
import Error404 from "../views/dashboard/errors/error404";
import Error500 from "../views/dashboard/errors/error500";

//extrpages
import Maintenance from "../views/dashboard/extrapages/maintenance";
import ComingSoon from "../views/dashboard/extrapages/comingsoon";
import ScannedQrcDetails from "../views/dashboard/app/CriticalQrc/ScannedQrcDetails";
import ScannedQrcDetailsLostFound from "../views/dashboard/app/LostFoundQrc/ScannedQrcDetailsLostFound";
import ScannedQrcAddDetails from "../views/dashboard/app/LostFoundQrc/ScannedQrcAddDetails";
import VcardTemplate from "../views/dashboard/store/VcardTemplate";
import PostTemplate from "../views/dashboard/PostTemplate";
import EventTemplate from "../views/dashboard/EventTemplate";

export const SimpleRouter = [
  // {
  //   path: "auth/confirm-mail",
  //   element: <ConfirmMail />,
  // },
  // {
  //   path: "auth/lock-screen",
  //   element: <LockScreen />,
  // },
  // {
  //   path: "auth/recoverpw",
  //   element: <Recoverpw />,
  // },
  // {
  //   path: "auth/sign-in",
  //   element: <SignIn />,
  // },
  // {
  //   path: "auth/sign-up",
  //   element: <SignUp />,
  // },
  // {
  //   path: "auth/forgot",
  //   element: <Forgot />,
  // },
  // {
  //   path: "auth/forgotpassword",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "critical-qrc-more-details/:qrcid",
  //   element: <ScannedQrcDetails />,
  // },
  // {
  //   path: "lost-qrc-add-details/:deviceid",
  //   element: <ScannedQrcAddDetails />,
  // },
  // {
  //   path: "lost-qrc-more-details/:qrcid",
  //   element: <ScannedQrcDetailsLostFound />,
  // },
  // {
  //   path: "vCard/:id",
  //   element: <VcardTemplate />,
  // },
  // {
  //   path: "post/:id",
  //   element: <PostTemplate />,
  // },
  // {
  //   path: "event/:id",
  //   element: <EventTemplate />,
  // },
  // {
  //   path: "errors/error404",
  //   element: <Error404 />,
  // },
  // {
  //   path: "errors/error500",
  //   element: <Error500 />,
  // },
  // {
  //   path: "extra-pages/pages-maintenance",
  //   element: <Maintenance />,
  // },
  // {
  //   path: "extra-pages/pages-comingsoon",
  //   element: <ComingSoon />,
  // },
  {
    path: "*",
    element: <Error404 />,
  },
];
