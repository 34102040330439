import React,{useEffect, useState} from 'react'
import {Row, Col, Form, Container, Dropdown, Modal,Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import {Link, useParams} from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';
import ShareOffcanvas from '../../../components/share-offcanvas'

// images
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import img5 from '../../../assets/images/icon/10.png'
import user1 from '../../../assets/images/user/1.jpg'
import user05 from '../../../assets/images/user/05.jpg'
import user02 from '../../../assets/images/user/02.jpg'
import user03 from '../../../assets/images/user/03.jpg'
import user06 from '../../../assets/images/user/06.jpg'
import user07 from '../../../assets/images/user/07.jpg'
import user08 from '../../../assets/images/user/08.jpg'
import user09 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
import icon1 from '../../../assets/images/icon/01.png'
import icon2 from '../../../assets/images/icon/02.png'
import icon3 from '../../../assets/images/icon/03.png'
import icon4 from '../../../assets/images/icon/04.png'
import icon5 from '../../../assets/images/icon/05.png'
import icon6 from '../../../assets/images/icon/06.png'
import icon7 from '../../../assets/images/icon/07.png'
import icon8 from '../../../assets/images/icon/08.png'
import icon9 from '../../../assets/images/icon/09.png'
import icon10 from '../../../assets/images/icon/10.png'
import icon11 from '../../../assets/images/icon/11.png'
import icon12 from '../../../assets/images/icon/12.png'
import icon13 from '../../../assets/images/icon/13.png'
import g1 from '../../../assets/images/page-img/g1.jpg'
import g2 from '../../../assets/images/page-img/g2.jpg'
import g3 from '../../../assets/images/page-img/g3.jpg'
import g4 from '../../../assets/images/page-img/g4.jpg'
import g5 from '../../../assets/images/page-img/g5.jpg'
import g6 from '../../../assets/images/page-img/g6.jpg'
import g7 from '../../../assets/images/page-img/g7.jpg'
import g8 from '../../../assets/images/page-img/g8.jpg'
import g9 from '../../../assets/images/page-img/g9.jpg'
import img56 from '../../../assets/images/page-img/p2.jpg'
import img58 from '../../../assets/images/page-img/p1.jpg'
import img57 from '../../../assets/images/page-img/p3.jpg'
import small07 from '../../../assets/images/small/07.png'
import small08 from '../../../assets/images/small/08.png'
import small09 from '../../../assets/images/small/09.png'
import small1 from '../../../assets/images/small/07.png'
import small2 from '../../../assets/images/small/08.png'
import small3 from '../../../assets/images/small/09.png'
import small4 from '../../../assets/images/small/10.png'
import small5 from '../../../assets/images/small/11.png'
import small6 from '../../../assets/images/small/12.png'
import small7 from '../../../assets/images/small/13.png'
import small8 from '../../../assets/images/small/14.png'
import user9 from '../../../assets/images/user/1.jpg'
import img59 from '../../../assets/images/page-img/59.jpg'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import CarouselN from 'react-bootstrap/Carousel';
import useAuth from '../../../utils/useAuth'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const FriendProfile =() =>{
   useAuth();
   const {userid} = useParams();

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [post, setPost] = useState([]);
   const [page, setPage] = useState(1);
   const [hasMore, setHasMore] = useState(true);



   const [expandedRows, setExpandedRows] = useState({});

   const toggleExpand = (index) => {
       setExpandedRows((prevExpandedRows) => ({
           ...prevExpandedRows,
           [index]: !prevExpandedRows[index],
       }));
      };
   const renderDescription = (data, index) => {
      const description = data?.description;
      const threshold = 180;

      if (description && description.length > threshold) {
          if (expandedRows[index]) {
              return (
                  <div>
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                      <button onClick={() => toggleExpand(index)} style={{ color: "#FF5500", background: "none", border: "none", cursor: "pointer", display: "block" }}>Show Less...</button>
                  </div>
              );
          } else {
              return (
                  <div>
                      <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: `${description.slice(0, threshold)}...` }} />
                      <button onClick={() => toggleExpand(index)} style={{ color: "#FF5500", background: "none", border: "none", cursor: "pointer", display: "inline" }}>Show More...</button>
                  </div>
              );
          } 
      } else {
          return <div dangerouslySetInnerHTML={{ __html: description }} />;
      }
  };
  const profileImage = localStorage.getItem('profileImage') ? localStorage.getItem('profileImage') : "/profile.png" ;
  
   const [imageController, setImageController] = useState({
      toggler: false,
      slide: 1
  });
  const [userDetail, setUserDetail] = useState("");
   // const handleUserDetail = async () => {
   //    try {
   //      const token = localStorage.getItem("token");
   //      const response = await axios.get(
   //        `${process.env.REACT_APP_URL}/api/user/scan-profile/${userid}`,
   //        {
   //          headers: {
   //            Authorization: `Bearer ${token}`,
   //            "Content-Type": "multipart/form-data",
   //          },
   //        }
   //      );
   //      console.log("User Detail fetched successfully");
   //      setUserDetail(response.data.data);
   //    } catch (error) {
   //      console.log("Failed Fetching User Detail", error);
   //    }
   //  };

   const [userIdProfile, setUserIdProfile] = useState("");
   const handleUserDetail = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/user/social-profile?userId=${userid}`
        );
        console.log("User Detail fetched successfullys", response.data.data);
        setUserDetail(response.data.data.userData);
        setUserIdProfile(response.data.data.userData._id);
        console.log("profile id", userIdProfile);
      } catch (error) {
        console.log("Failed Fetching User Detail", error);
      }
    };

    useEffect(()=>{
      handleUserDetail();
    },[userid]);

    const handleAllPost = async (updateData = false) => {
      try {
          const token = localStorage.getItem('token');
          const response = await axios.get(
              `${process.env.REACT_APP_URL}/api/post/all?page=${page}&limit=5&userId=${userIdProfile}`, {
              headers: {
                  "Authorization": `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
              }
          }
          )
          console.log("Post Fetched Successfully", response.data.data.allPosts);
          // setPost(response.data.data.allPosts);
          // setHasMore(response.data.data.allPosts.length > 0);
          if (updateData) {
              setPost(response.data.data.allPosts);
            } else {
              setPost((prevPost) => [...prevPost, ...response.data.data.allPosts]);
            }
            setHasMore(response.data.data.allPosts.length > 0);

      } catch (error) {
          console.log("Failed Fetching Posts", error);
      }
  }

  useEffect(() => {
   if(userIdProfile)
      {
         handleAllPost();
      }
}, [userIdProfile,page])

    const handleSendRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/connection/sent-request`,{
            requestTo: userIdProfile,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        toast.success(response.data.message)
        console.log("Request Send successfully");
      } catch (error) {
         toast.error(error.response.data.message)
        console.log("Failed Sending Friend Request", error);
      }
    };

    const handleSavePost = async(postID) => {
      try {
          const token = localStorage.getItem('token');
          const response = await axios.post(
              `${process.env.REACT_APP_URL}/api/post/save/${postID}`,{ },{
                  headers: {
                      "Authorization": `Bearer ${token}`,
                      // "Content-Type": "multipart/form-data"
                  }
              }
          )
          console.log("Post Saved", response.data);
          toast.success(response.data.message)
      } catch (error) {
          toast.error(error?.response?.data?.message || "Failed Saving Post");
          console.log("Failed Saving Posts", error);
      }
  }

  function imageOnSlide(number) {
      setImageController({
      toggler: !imageController.toggler,
      slide: number
      }); 
  }
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const [qrImageUrl, setQrImageUrl] = useState('');
  const handleQrClick = (imageUrl) => {
   setQrImageUrl(imageUrl);
   setQrPopupOpen(true);
 };

 const closeQrPopup = () => {
   setQrPopupOpen(false);
 };

 const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportDescription,setReportDescription] = useState("");
  const [reportId, setReportId] = useState("");
  const handleReportClick = (reqid) => {
    setReportId(reqid);
    setReportModalOpen(true);
  };

  const closeReportModal = () => {
    setReportModalOpen(false);
  };
  const handleReport = async () => {
    if (!reportDescription) {
        toast.info("Report issue is required");
        return;
      }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/report`,
        {
          reportFor: "post",
          issueDescription: reportDescription,
          userId: "",
          postId: reportId,
          eventId: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      closeReportModal();
      setReportId("");
      setReportDescription("");
      console.log("Post Reported", response.data);
      toast.success(response.data.message);

    } catch (error) {
        setReportId("");
      setReportDescription("");
      toast.error(error?.response?.data?.message || "Failed Reporting Post");
      console.log("Failed SaviReportingng Posts", error);
    }
  };

  const handleCopyUrl = (postId) => {
   const url = `https://dirido.com/post/${postId}`;
   navigator.clipboard.writeText(url).then(() => {
   //   alert('URL copied to clipboard');
   toast.success("URL Copied")
   }).catch(err => {
     console.error('Could not copy text: ', err);
   });
 };

   return(
      <>
      <ToastContainer/>
         <FsLightbox
                toggler={imageController.toggler}
                sources={[g1,g2,g3,g4,g5,g6,g7,g8,g9]}
                slide={imageController.slide}
            />
         <Container>
            <Row>
               <Col sm={12}>
                  
                  <Card>
                     <Card.Body className=" profile-page p-0">
                        <div className="profile-header profile-info">
                           <div className="cover-container">
                              <img loading="lazy" src={img1} alt="profile-bg" className="rounded img-fluid"/>
                              <ul className="header-nav d-flex flex-wrap justify-end p-0 m-2" >
                                 {/* <li>
                                    <Link to="#" className="material-symbols-outlined">
                                       edit
                                    </Link>
                                 </li> */}
                                 {/* <li>
                                 <button  className="btn btn-primary d-block w-100 mt-3" onClick={handleTest}>Send Request</button>

                                 </li> */}
                              </ul>
                           </div>
                           <div className="user-detail text-center mb-3">
                              <div className="profile-img">
                                 <img loading="lazy" src={userDetail?.profileImageLink ? userDetail?.profileImageLink : "/profile.png"} alt="profile-img" className="avatar-130 img-fluid"  style={{width:"120px", height:"120px"}}/>
                              </div>
                              <div className="profile-detail">
                                 <h3>{userDetail?.firstName} {userDetail?.lastName}</h3>
                              </div>
                              {/* <div style={{display:"flex", justifyContent:"center", marginBottom:"-5px" }}  > 
                                  <button  className="btn btn-primary d-block mt-3" style={{display:"flex",width:"200px" }}  >Send Request</button>
</div> */}
                           </div>
                           
                           <div className="profile-info p-4 d-flex align-items-center justify-content-between position-relative" >
                              <div className="social-links" >
                                 <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                    {/* <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon8} className="img-fluid rounded" alt="facebook"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon9} className="img-fluid rounded" alt="Twitter"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon10} className="img-fluid rounded" alt="Instagram"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon11} className="img-fluid rounded" alt="Google plus"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon12} className="img-fluid rounded" alt="You tube"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={icon13} className="img-fluid rounded" alt="linkedin"/></Link>
                                    </li> */}
                                     <li className="text-center pe-3">
                                       <h6>Posts</h6>
                                       <p className="mb-0">690</p>
                                    </li>
                                    <li className="text-center pe-3">
                                       <h6>Followers</h6>
                                       <p className="mb-0">206</p>
                                    </li>
                                    <li className="text-center pe-3">
                                       <h6>Following</h6>
                                       <p className="mb-0">100</p>
                                    </li>
                                 </ul>
                              </div>
                              <div className="social-info" >
                                 <ul className="social-data-block d-flex align-items-center justify-content-center list-inline p-0 m-0" style={{justifyContent:"center"}}>
                                    <li className="text-center ">
                                    <div style={{display:"flex", justifyContent:"center"}}> 
                                  <button  className="btn btn-primary d-block " style={{display:"flex",width:"200px" }}  onClick={handleSendRequest}>Send Request</button>
</div>
                                    </li>
                                    
                                    {/* <li className="text-center pe-3">
                                       <h6>Followers</h6>
                                       <p className="mb-0">206</p>
                                    </li>
                                    <li className="text-center pe-3">
                                       <h6>Following</h6>
                                       <p className="mb-0">100</p>
                                    </li> */}
                                 </ul>
                              </div>
                              
                           </div>
                          
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
               <Row>
                  
                  <Col lg={12}>
                  <Card>
                        <div className="card-header d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">About</h4>
                           </div>
                        </div>
                        <Card.Body>
                           <ul className="list-inline p-0 m-0">
                              <li className="mb-2 d-flex align-items-center">
                              <span className="material-symbols-outlined md-18">
                                 email
                              </span>
                                 <p className="mb-0 ms-2">{userDetail?.email}</p>
                              </li>
                              <li className="mb-2 d-flex align-items-center">
                              <span className="material-symbols-outlined md-18">
                                 call
                              </span>  
                                 <p className="mb-0 ms-2">{userDetail?.mobile}</p>
                              </li>
                              {/* <li className="mb-2 d-flex align-items-center">
                              <span className="material-symbols-outlined md-18">
                                 place
                              </span>
                                 <p className="mb-0 ms-2">USA</p>
                              </li>
                              <li className="d-flex align-items-center">
                              <span className="material-symbols-outlined md-18">
                                 favorite_border
                              </span>  
                                 <p className="mb-0 ms-2">Single</p>
                              </li> */}
                           </ul>
                        </Card.Body>
                     </Card>
                  <InfiniteScroll
                     dataLength={post.length}
                     next={() => setPage((page) => page + 1)}
                     hasMore={hasMore}
                     loader={
                        <div className="col-sm-12 text-center">
                           <img src={loader} alt="loader" style={{ height: "100px" }} />
                        </div>
                     }
                     endMessage={<p className="text-center">No posts available</p>}
                     >
                            {post?.map((data, index) => (
                  <Col lg={12}>
                     
                     <Card>
                        <Card.Body>
                           <div className="post-item">
                              <div className="user-post-data p-3">
                                 <div className="d-flex justify-content-between">
                                    <div className="me-3">
                                    <img className="rounded-circle img-fluid" src={data?.userId?.profileImageLink ? data?.userId?.profileImageLink : "/profile.png"} alt="" style={{width:"55px",height:"50px"}}/>
                                    </div>
                                    <div className="w-100">
                                       <div className="d-flex justify-content-between flex-wrap">
                                          <div>
                                          <h5 className="mb-0 d-inline-block">{data?.userId?.firstName} {data?.userId?.lastName}</h5>

                                          <p className="mb-0 " style={{textTransform:"capitalize"}}>{moment(data?.createdAt).fromNow()}</p>
                                          </div>
                                          <div className="card-post-toolbar" style={{ display: "flex",gap:"30px" }}>
                                             {data?.userId?.qrCodeUrl ? (<div>
                                                                    <img src={data?.userId?.qrCodeUrl}
                                                                    onClick={() => handleQrClick(data?.userId?.qrCodeUrl)}
                                                                     style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        
                                                                    }} />
                                                                    

                                                                 
                                                                </div>) : (
                                                                  <div>

                                                               
                                                              </div>
                                                                )}
                                                                
                                                                <Dropdown >
                                                                    <Dropdown.Toggle variant="bg-transparent">
                                                                        <span className="material-symbols-outlined">
                                                                            more_horiz
                                                                        </span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                        <Dropdown.Item className=" p-3" onClick={() => handleSavePost(data._id)}>
                                                                            <div className="d-flex align-items-top">
                                                                                <div className="h4 material-symbols-outlined">
                                                                                    <i className="ri-save-line"></i>
                                                                                </div>
                                                                                <div className="data ms-2">
                                                                                    <h6>Save Post</h6>
                                                                                    <p className="mb-0">Add this to your saved items</p>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="p-3" onClick={() => handleReportClick(data._id)}>
                                                                            <div className="d-flex align-items-top">
                                                                                <i className="ri-close-circle-line h4"></i>
                                                                                <div className="data ms-2">
                                                                                    <h6>Report Post</h6>
                                                                                    <p className="mb-0">Report this post for inappropriate
                                              content</p>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="p-3" to="#" onClick={() => handleCopyUrl(data?._id)}>
          <div className="d-flex align-items-top">
            <i className="ri-link h4"></i>
            <div className="data ms-2">
              <h6>Copy URL</h6>
              <p className="mb-0">Copy post URL to clipboard</p>
            </div>
          </div>
        </Dropdown.Item>
                                                                        {/* <Dropdown.Item className=" p-3" to="#">
                                                                            <div className="d-flex align-items-top">
                                                                                <i className="ri-user-unfollow-line h4"></i>
                                                                                <div className="data ms-2">
                                                                                    <h6>Unfollow User</h6>
                                                                                    <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className=" p-3" to="#">
                                                                            <div className="d-flex align-items-top">
                                                                                <i className="ri-notification-line h4"></i>
                                                                                <div className="data ms-2">
                                                                                    <h6>Notifications</h6>
                                                                                    <p className="mb-0">Turn on notifications for this post</p>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item> */}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <Modal
                    size="md"
                    className="fade"
                    id="qr-modal"
                    onHide={closeQrPopup}
                    show={qrPopupOpen}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>QR Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                      <img
                        src={qrImageUrl}
                        alt="QR Code"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-secondary"
                        onClick={closeQrPopup}
                      >
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
        size="md"
        className="fade"
        id="report-modal"
        onHide={closeReportModal}
        show={reportModalOpen}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Post</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "450px", overflowY: "auto" }}>
                <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0 ">
                  <li className="col-12 ">
                    <Form.Group className="form-group">
                      <Form.Label>What are you trying to report?</Form.Label>
                      <Form.Control
                        type="text"
                        value={reportDescription}
                        onChange={(e)=> setReportDescription(e.target.value)}
                        className="mb-0 py-2"
                        id=""
                        placeholder="Report issue..."
                        required
                      />
                    </Form.Group>
                  </li>
                </ul>

                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 mt-3"
                  onClick={handleReport}
                >
                  Report
                </button>
              </Modal.Body>
      </Modal>
                                                            </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="mt-3">
                                                <p>{renderDescription(data, index)}</p>
                                                {/* <p>{data?.description}</p> */}
                                            </div>
                                            <div className="user-post">
                            <CarouselN style={{ maxWidth: '600px', margin: '0 auto' }}>
                                {data?.photos?.map((photo, photoIndex) => (
                                    <CarouselN.Item key={photoIndex}>
                                        <img
                                            className="d-block w-100"
                                            src={photo}
                                            alt={`post-photo-${photoIndex}`}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                            }}
                                        />
                                    </CarouselN.Item>
                                ))}
                                </CarouselN>
                        </div>
                              
                           </div>
                           
                        </Card.Body>
                     </Card>
                  </Col>
                     ))}
                     </InfiniteScroll>
                     </Col>
               </Row>
            </Row>
         </Container>
      </>
  )

}

export default FriendProfile;