import { Row, Col, Container, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
// import './Style.css';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import user1 from '../../../../assets/images/page-img/1.jpg'
import error404 from '../../../../assets/images/error/01.jpg'
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { SiTicktick } from "react-icons/si";


// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);
const ScannedQrcAddDetails = () => {
   let navigate = useNavigate()
   const params = useParams();
   const [qrc, setQrc] = useState(null);
   const [files, setFiles] = useState([]);
   const [previewUrl, setPreviewUrl] = useState([]);
   const [errorMessage, setErrorMessage] = useState('');
   const [thankyouMessage, setThankyouMessage] = useState(false);

   const [formData1, setFormData1] = useState({
      'firstName': null,
      'lastName': null,
      'email': null,
      'message': null,
      "contactNumber": null,
      'document': []
   });
   const MAX_IMAGES = 3;
   console.log('previewUrl', previewUrl)
   const [isEmailValid, setIsEmailValid] = useState(false);
   const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData1(prevState => ({
         ...prevState,
         [name]: value
      }));
   };
   const handleRemoveImage = (index) => {
      setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
      setPreviewUrl(prevPreviews => prevPreviews.filter((_, i) => i !== index));
   };
   const handleSubmitData = async (e) => {
      e.preventDefault();
      if (!formData1.firstName || !formData1.lastName || !formData1.email || !formData1.message) {
         toast.error('all fields are required');
         return;
      }
      if (!formData1.contactNumber) {
         toast.error('Contact Number is not valid!');
         return;
      }
      if (isEmailValid) {
         toast.error('Email is not valid!');
         return;
      }
      const formData = new FormData();
      files.forEach(file => formData.append('document', file))
      formData.append('firstName', formData1.firstName);
      formData.append('lastName', formData1.lastName);
      formData.append('email', formData1.email);
      formData.append('contactNumber', formData1.contactNumber);
      formData.append('message', formData1.message);

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/device/found/${params?.deviceid}`, formData,

         );
         // toast.success(response?.data?.message);
         toast?.success(response?.data?.message)
         setFormData1({
            'firstName': '',
            'lastName': '',
            'email': '',
            'message': '',
            "contactNumber": '',
         })
         setThankyouMessage(true);
      } catch (error) {
         toast.error(error?.response?.data?.message);
      }
   }
   const handleImageChange = (event) => {
      const files = Array.from(event.target.files);
      const validFiles = files.filter(file => file.type.startsWith('image/'));
      if (files[0]?.size > 2097152) {
         toast.warning('Entity is too large')
         return;
      };
      if (validFiles.length !== files.length) {
         toast.warning('Only image files are allowed.');
         return;
      }
      if (previewUrl?.length > MAX_IMAGES) {
         toast.warning(`You can only upload up to ${MAX_IMAGES} images.`);
         return;
      }
      const previews = validFiles.map(file => URL.createObjectURL(file));
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
      setPreviewUrl(prevPreviews => [...prevPreviews, ...previews]);
   };
   

   return (
      <>
         <section className="d-flex justify-content-center " style={{ height: "100lvh" }}>

            <Container className="p-0" style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
               {/* <div style={{ fontSize: "30px", letterSpacing: "4px", color: "white", fontWeight: "600" }} className={window.innerWidth <= 768 ? "mobile-margin-top" : ""}>DIRIDO</div> */}

               <Row className="no-gutters w-100 p-5" style={{ borderRadius: "10px", overflow: "hidden", justifyContent: "center", display: "flex", margin: "20px" }}>
                  <Col md="12" className=" pb-5 d-flex flex-column justify-content-around ">
                     {
                        thankyouMessage ?
                           <div className='text-center flex flex-col items-center justify-center'>
                              <SiTicktick className='' style={{ fontSize: '90px', color: '#00d900' }} />

                              <h1>Thank you! </h1>
                              <h4>Your submission has been sent! </h4>
                           </div> :
                           <form
                              className="post-text w-100 d-flex flex-column justify-content-between"
                              style={{ height: "80vh" }}
                           // onSubmit={handleSubmit}
                           >
                              <div className='' >
                                 <div>
                                    <h3><b>Let’s get you started</b></h3>
                                 </div>
                                 <div className='mt-3'>
                                    <input
                                       className="form-control rounded py-4"
                                       type="text"
                                       name='firstName'
                                       placeholder="First Name"
                                       style={{ border: "none", borderBottom: "2px solid #cfcfcf" }}
                                       value={formData1?.firstName}
                                       onChange={handleChange}
                                    />
                                    <input
                                       className="form-control rounded py-4"
                                       type="text"
                                       placeholder="Last Name"
                                       name='lastName'
                                       style={{ border: "none", borderBottom: "2px solid #cfcfcf" }}
                                       value={formData1?.lastName}
                                       onChange={handleChange}
                                    />
                                    <input
                                       className="form-control rounded py-4"
                                       type="email"
                                       name='email'
                                       placeholder="Email address"
                                       style={{ border: "none", borderBottom: "2px solid #cfcfcf" }}
                                       value={formData1?.email}
                                       onChange={(e) => {
                                          handleChange(e);
                                          if (!emailRegex.test(e.target.value))
                                             setIsEmailValid(true);
                                          else setIsEmailValid(false);
                                       }}

                                    />
                                    {isEmailValid &&
                                       <div
                                          className="text-danger text-nowrap"
                                          style={{ fontSize: "12px" }}
                                       >
                                          Please enter a valid email address!
                                       </div>
                                    }
                                    <div className="d-flex mt-2 " >
                                       {/* <select style={{ width: "20%", border: "none", borderBottom: "2px solid #cfcfcf" }} name="mobileCode" value={formData1.mobileCode} onChange={handleChange}
                                    className="mb-0 form-control rounded py-4" id="mobileCode" required>
                                    <option value="+91" selected>+91</option>
                                    <option value="+92">+92</option>
                                 </select> */}
                                       <input
                                          className="form-control rounded py-4"
                                          style={{ border: "none", borderBottom: "2px solid #cfcfcf" }}
                                          name="contactNumber"
                                          id="mobile"
                                          placeholder="Enter Phone Number"
                                          required
                                          type="tel"
                                          maxLength={10}
                                          value={formData1.contactNumber}
                                          onChange={(e) => {
                                             const re = /^[0-9\b]+$/;
                                             if (e.target.value === '' || re.test(e.target.value)) {
                                                setFormData1(prevState => ({
                                                   ...prevState,
                                                   contactNumber: e.target.value
                                                }));
                                             }
                                          }}

                                       />
                                    </div>
                                    <textarea
                                       className="form-control rounded py-4"
                                       type="text"
                                       rows={3}
                                       placeholder="Type description..."
                                       name='message'
                                       style={{ border: "none", borderBottom: "2px solid #cfcfcf" }}
                                       value={formData1?.message}
                                       onChange={handleChange}
                                    />
                                 </div>
                                 <label
                                    htmlFor="file-input"
                                    style={{ cursor: "pointer", width: "100%" }}
                                 >

                                    <input
                                       id="file-input"
                                       type="file"
                                       name="image"
                                       accept="image/*,.doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                       multiple
                                       style={{ display: "none" }}
                                       onChange={(e) => {
                                          handleImageChange(e)
                                       }}
                                    />

                                    <p className="w-100 mt-3 bg-white border-0 text-primary "> + Upload Image</p>
                                    <div className="d-flex gap-3 ">
                                       {
                                          previewUrl?.length > 0 &&
                                          previewUrl?.map((img, index) =>

                                             <div key={index} style={{ position: 'relative', margin: '5px' }}>
                                                <img src={img} alt={`preview ${index}`} style={{ width: "80px", height: "80px" }} className="rounded" />
                                                <button
                                                   onClick={() => handleRemoveImage(index)}
                                                   style={{ position: 'absolute', top: '0', right: '0' }}
                                                   className='rounded bg-white border border-red'
                                                >
                                                   X
                                                </button>
                                             </div>
                                          )
                                       }
                                    </div>

                                 </label>
                              </div>
                              <div className="d-flex justify-content-between gap-3 mt-3">
                                 <Button className="w-100 mt-3 border-0" type="submit" onClick={handleSubmitData}>Submit</Button>
                              </div>
                           </form>

                     }
                  </Col>
               </Row>
            </Container>
         </section>
         <ToastContainer />
      </>
   )
}

export default ScannedQrcAddDetails
