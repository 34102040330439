import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../auth/Style.css';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import user1 from '../../../../assets/images/page-img/1.jpg'
import whatsapp from '../../../../assets/images/whatsapp.svg'
import pdf from '../../../../assets/images/pdf.png'
import { useEffect, useState } from 'react';
import { FaAngleRight, FaCaretRight } from 'react-icons/fa6';
import axios from 'axios';
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const ScannedQrcDetails = () => {
   let navigate = useNavigate()
   const [contactModal, setContactModal] = useState(null);
   const [contactModalDetails, setContactModalDetails] = useState(null);
   const params = useParams();
   const [qrc, setQrc] = useState(null);
   const [contactData, setContactData] = useState(null);
   const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
   const [error, setError] = useState(null);
   const [findUploadedConditionDocs, setFindUploadedConditionDocs] = useState([]);


   const getLocation = () => {
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(
            (position) => {
               setUserLocation({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
               });
               setError(null);
            },
            (error) => {
               setError(error.message);
            }
         );
      } else {
         setError('Geolocation is not supported by this browser.');
      }
   };
   const splitFileLink = (a) => {
      const x = a.split('_');
      const y = x[x.length - 1];
      return decodeURIComponent(y);
   }

   const handleGenerateQrc = async () => {
      // e.preventDefault();
      const dataToSent = {
         "location": userLocation,
         "device": ""
      }

      try {
         const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/qrc/scan/${params?.qrcid}`, dataToSent,

         );
         // toast.success(response?.data?.message);
         setQrc(response?.data?.data?.qrcContentData);

      } catch (error) {
         toast.error(error?.response?.data?.message);
      }
   }
   useEffect(() => {
      getLocation();
      if (userLocation?.latitude) {
         handleGenerateQrc();
      }
   }, [!userLocation?.latitude])
   useEffect(() => {
      let a = qrc?.qrcContentId?.conditionIds?.filter(array => array?.conditionDocuments?.length > 0);
      setFindUploadedConditionDocs(a);
   }, [qrc])

   return (
      <>
         <section className="d-flex justify-content-center " >

            <Container className="p-0" style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
               {/* <div style={{ fontSize: "30px", letterSpacing: "4px", color: "white", fontWeight: "600" }} className={window.innerWidth <= 768 ? "mobile-margin-top" : ""}>DIRIDO</div> */}

               <Row className="no-gutters w-100 px-1" style={{ borderRadius: "10px", justifyContent: "center", display: "flex", margin: "20px" }}>
                  {
                     qrc ?
                        <Col md="12" className=" d-flex flex-column justify-content-center " >
                           <div className='text-center'><img src='/Logo.png' style={{ height: "50px" }} /></div>
                           <div className='d-flex flex-column justify-content-between align-items-center mt-1'>
                              <img src={qrc?.userId?.profileImageLink ? qrc?.userId?.profileImageLink : '/profile.png'} alt='img' className='rounded-circle' style={{ width: "150px", height: "150px" }} />
                              <div className='mt-3 d-flex justify-content-center flex-column align-items-center'>
                                 <b style={{ fontSize: "15px" }}>You’ve scanned for </b>
                                 <b style={{ fontSize: "20px" }}>{qrc?.userId?.firstName} {qrc?.userId?.lastName}</b>
                              </div>
                              {
                                 qrc?.qrcContentId?.categoryId?.categoryName &&
                                 <div className='text-danger p-1 px-4' style={{
                                    fontSize: "18px",
                                    border: '2px solid #e9e9e9',
                                    fontSize: '18px',
                                    borderRadius: '120px ',
                                 }}
                                 >{qrc?.qrcContentId?.categoryId?.categoryName}</div>
                              }
                              {
                                 qrc?.qrcContentId?.adviceId?.adviceVoiceNoteLink &&
                                 <div className='mt-2 w-100'>
                                    <h5><b>A voice message added by {qrc?.userId?.firstName}</b></h5>

                                    <div className='w-100'>
                                       <audio controls className='w-100'>
                                          <source src={qrc?.qrcContentId?.adviceId?.adviceVoiceNoteLink} />
                                          Your browser does not support the audio element.
                                       </audio>
                                    </div>


                                 </div>
                              }
                              {
                                 qrc?.qrcContentId?.adviceId?.adviceText &&
                                 <div className='mt-2 w-100'>
                                    <h5><b>Here’s some advice in case of emergencies</b></h5>
                                    <p style={{ background: "#ededed" }} className='rounded p-2 mt-2'>{qrc?.qrcContentId?.adviceId?.adviceText}</p>
                                 </div>
                              }

                              {
                                 findUploadedConditionDocs?.length > 0 &&
                                 <div className='w-100 mt-2'>
                                    <h5><b>Additional documents uploaded by the doctor</b></h5>
                                    <div className='d-flex gap-4 flex-wrap'>
                                       {findUploadedConditionDocs?.map((i) =>
                                          i?.conditionDocuments?.map((doc, index) =>
                                             <div className='d-flex gap-4 flex-wrap' key={index}>
                                                <div className='d-flex flex-column' >
                                                   <Link to={`${doc}`} target="_blank" className='pointer text-dark d-flex flex-column' style={{ fontSize: "10px", textDecoration: "none" }}>
                                                      <img src={pdf} alt='documents' style={{
                                                         background: 'rgb(221 221 221)',
                                                         borderRadius: '20px',
                                                         padding: '10px',
                                                         width: '50px',
                                                         height: '50px',
                                                      }} />
                                                      {splitFileLink(doc)}<br />
                                                   </Link>
                                                </div>
                                             </div>
                                          )
                                       )}
                                    </div>
                                 </div>
                              }


                              {
                                 qrc?.qrcContentId?.adviceId?.adviceDocuments?.length > 0 &&
                                 <div className='w-100 mt-2'>
                                    <h5><b>Documents uploaded in Advice</b></h5>
                                    <div className='d-flex gap-4 flex-wrap'>
                                       {qrc?.qrcContentId?.adviceId?.adviceDocuments?.map((doc, index) =>
                                          <div className='d-flex gap-4 flex-wrap' key={index}>
                                             <div className='d-flex flex-column' >
                                                <Link to={`${doc}`} target="_blank" className='pointer text-dark d-flex flex-column' style={{ fontSize: "10px", textDecoration: "none" }}>
                                                   <img src={pdf} alt='documents' style={{
                                                      background: 'rgb(221 221 221)',
                                                      borderRadius: '20px',
                                                      padding: '10px',
                                                      width: '50px',
                                                      height: '50px',
                                                   }} />
                                                   {splitFileLink(doc)}<br />
                                                </Link>
                                             </div>
                                          </div>
                                       )}

                                    </div>
                                 </div>
                              }



                              {
                                 qrc?.qrcContentId?.conditionIds?.length > 0 &&
                                 <div className='mt-2 w-100'>
                                    <h5><b>{`${qrc?.userId?.firstName}'s`} condition</b></h5>
                                    <p style={{ background: "#ededed" }} className='rounded p-2 mt-2'>
                                       <ol>
                                          {
                                             qrc?.qrcContentId?.conditionIds?.map((i, index) =>
                                                <div key={index}>
                                                   <li style={{ fontWeight: "500" }}>
                                                      <div className='d-flex gap-1'>
                                                         <label><b>Condition:</b></label>
                                                         <div> {i?.conditionName}</div>
                                                      </div>
                                                   </li>

                                                   <div className='d-flex gap-1'>
                                                      <label><b>Description:</b></label>
                                                      <p>{i?.conditionDescription}</p>
                                                   </div>

                                                </div>
                                             )
                                          }
                                       </ol>

                                    </p>
                                 </div>
                              }
                           </div>
                           <div className='d-flex flex-column gap-4'>
                              <Button onClick={() => setContactModal(true)}>Contact the emergency number</Button>
                           </div>
                           <Modal
                              size="lg"
                              className="fade text-center d-flex justify-content-center align-items-center modal-scanned-details "
                              id="post-modal"
                              onHide={() => setContactModal(null)}
                              show={contactModal}
                              style={{ overflow: "hidden" }}
                           >
                              <Link to="#" className="lh-1 text-end p-2" onClick={() => setContactModal(null)}>
                                 <span className="material-symbols-outlined">close</span>
                              </Link>

                              <Modal.Body
                                 className='p-3 pb-4'
                                 style={{ maxHeight: "550px", overflowY: "auto" }}
                              >
                                 <div id="post-modalLabel" style={{ fontSize: "15px" }}>
                                    <b> {qrc?.userId?.firstName} has listed {qrc?.qrcContentId?.emergencyContactIds?.length} emergency contacts</b>
                                 </div>
                                 <div className='mt-3 ' >
                                    {
                                       qrc?.qrcContentId?.emergencyContactIds?.map((i) =>
                                          <div key={i?._id}
                                             className='mt-3 d-flex justify-content-between align-items-center px-3 bg-primary text-white rounded p-2'
                                             onClick={() => { setContactModalDetails(true); setContactModal(null); setContactData(i) }}
                                          >
                                             <div>{i?.firstName + '' + i?.lastName}</div>
                                             <FaAngleRight />
                                          </div>
                                       )
                                    }
                                 </div>
                              </Modal.Body>
                           </Modal>
                           <Modal
                              size="lg"
                              className="fade text-center d-flex justify-content-center align-items-center modal-scanned-details "
                              id="post-modal"
                              onHide={() => setContactModalDetails(null)}
                              show={contactModalDetails}
                              style={{ overflow: "hidden" }}
                           >
                              <Link to="#" className="lh-1 text-end p-2" onClick={() => { setContactModal(true); setContactModalDetails(null) }}>
                                 <span className="material-symbols-outlined">close</span>
                              </Link>

                              <Modal.Body
                                 className='p-4 pb-4'
                                 style={{ maxHeight: "550px", overflowY: "auto" }}
                              >

                                 <div id="post-modalLabel" style={{ fontSize: "15px" }}>
                                    <b> {contactData?.firstName + ' ' + contactData?.lastName}</b>
                                 </div>
                                 <div className='d-flex flex-column gap-3 mt-3'>
                                    <div className='d-flex justify-content-between align-items-center px-3 bg-primary text-white rounded p-2' >
                                       <div className='d-flex gap-3 align-items-center'><img src={whatsapp} alt='whatsapp' />{contactData?.mobileCode + '' + contactData?.mobile}</div>
                                       <FaAngleRight />
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center px-3 bg-primary text-white rounded p-2' >
                                       <div>{contactData?.mobileCode + '' + contactData?.mobile}</div>
                                       <FaAngleRight />
                                    </div>
                                 </div>
                                 <div className='mt-4'>
                                    <b onClick={() => { setContactModal(true); setContactModalDetails(null) }}>Go Back</b>
                                 </div>
                              </Modal.Body>
                           </Modal>
                           <b style={{ fontSize: "20px" }} className='mt-3 text-center'>Powered by DIRIDO</b>
                        </Col>
                        :
                        <div style={{ height: "100vh" }} className='justify-content-center align-items-center d-flex'>
                           <div id='orderLoading'></div>
                        </div>
                  }
               </Row>
            </Container>
         </section >
         <ToastContainer />
      </>
   )
}

export default ScannedQrcDetails
