import React, { useState } from 'react'
import {Row, Col, Container, Form, Button, Image} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import './Style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthData } from '../../../utils/auth';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Forgot = () => {
   let navigate =useNavigate()

 const [formData, setFormData] = useState({
   username:"",
 })

 const handleChange = (event) => {
   const { id, value } = event.target;
   setFormData((prevFormData) => ({
      ...prevFormData,
      [id] : value
   }));
 };
 

 const handleForgot = async (e) => {
    e.preventDefault();
    try {
       const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/auth/forget-password`, {
          email: formData.username,
       }, {
        headers: {
           "Content-type": "application/json"
        }
     });
       console.log("Reset password link send to email", response.data);
       toast.success(response.data.message)
      
    //    navigate("/");
    } catch (error) {
        if (error.response && error.response.data && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
         } else {
            toast.error("An error occurred while resetting password.");
         }
         
       console.log("Error in reset password ", error);
    }
 }

   return (
      <>
         <section className="sign-in-page" style={{height:"100lvh"}}>
          
            <Container className="p-0" style={{alignItems:"center", display:"flex",flexDirection:"column", justifyContent:"center"}}>
            <div style={{ fontSize: "30px", letterSpacing: "4px", color: "white", fontWeight: "600" }} className={window.innerWidth <= 768 ? "mobile-margin-top" : ""}>DIRIDO</div>

               <Row className="no-gutters" style={{borderRadius:"10px",overflow:"hidden", justifyContent:"center", display:"flex", margin:"20px"}}>
                 
                  <Col md="12" className="bg-white pt-5   pb-5" style={{width:"400px"}}>
                     <div className="sign-in-from" style={{paddingInline:"20px"}}>
                        <h3 className="mb-0">Forgot Password</h3>
                        {/* <p>Enter your email address and password to access admin panel.</p> */}
                        <Form className="mt-4 " onSubmit={handleForgot}>
                           <Form.Group className="form-group">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control type="email" className="mb-0" id="username" placeholder="Enter email" value={formData.username} onChange={handleChange}/>
                           </Form.Group>
                           
                           <div className="d-inline-block w-100">
                             
                              <Button variant="primary" type="submit"  className="float-end" >Reset Password</Button>
                           </div>
                           
                        </Form>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>   
         <ToastContainer/>
      </>
   )
}

export default Forgot
