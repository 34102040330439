import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAuthData } from "../../../utils/auth";
import axios from "axios";
import { Dropdown, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileCode: "+91",
    mobile: "",
    email: "",
    password: "",
    emailOTP: "",
    mobileOTP: "",
    confirmPassword: "",
  });
  const [tc, setTc] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: val,
    }));

    if (name === "password") {
      const isValid = validatePassword(val);
      if (!isValid) {
        setPasswordError(
          "At least 8 characters with upper/lowercase, number, and special character."
        );
      } else {
        setPasswordError("");
      }
    }
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(password);
  };

  const [passwordError, setPasswordError] = useState("");
  const [categorypost, setCategorypost] = useState("public");

  const handleCategoryChange = (e) => {
    setCategorypost(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!validatePassword(formData.password)) {
      setPasswordError(
        "At least 8 characters with upper/lowercase, number, and special character."
      );
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/user/register`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          mobileCode: formData.mobileCode,
          mobile: formData.mobile,
          email: formData.email,
          password: formData.password,
          clientUseFor: categorypost,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Registered Successfully", response.data);
      toast.success(response.data.message);
      navigate("/auth/sign-in");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while signing up.");
      }

      console.log("Error in Sign up", error);
    }
  };
  const [generate, setGenerate] = useState(true);
  const [verify, setVerify] = useState(false);
  const [register, setRegister] = useState(false);

  // Generate OTP
  const handleGenerateOTP = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.info("Please Enter Email");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/auth/generateOTP`,
        {
          mobile: formData.mobile,
          email: formData.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OTP Send Successfully", response.data);
      toast.success(response.data.message);
      setGenerate(false);
      setVerify(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while generating OTP.");
      }

      console.log("Error in Generating OTP", error);
    }
  };

  // Verify OTP
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    if (!formData.emailOTP) {
      toast.info("Please Enter OTP");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/auth/verifyOTP`,
        {
          email: formData.email,
          emailOTP: formData.emailOTP,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OTP Verified Successfully", response.data);
      toast.success(response.data.message);
      setVerify(false);
      setRegister(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while verifying OTP.");
      }

      console.log("Error in verifying OTP", error);
    }
  };
  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <section className="sign-in-page" style={{ height: "100vh" }}>
      <Modal
      size="md"
      className="fade custom-modal"
      id="qr-modal"
      onHide={closePopup}
      show={popupOpen}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center custom-modal-body">
        <div>
          <h3>Terms and Conditions</h3>
          <p>Welcome to our application. These terms and conditions outline the rules and regulations for the use of our services.</p>
          <p>By accessing this application, we assume you accept these terms and conditions in full. Do not continue to use our application if you do not accept all of the terms and conditions stated on this page.</p>
          
          <h4>License</h4>
          <p>Unless otherwise stated, we and/or our licensors own the intellectual property rights for all material on the application. All intellectual property rights are reserved. You may view and/or print pages from the application for your own personal use subject to restrictions set in these terms and conditions.</p>
          
          <h4>User Comments</h4>
          <p>This Agreement shall begin on the date hereof. Certain parts of this application offer the opportunity for users to post and exchange opinions, information, material, and data ('Comments') in areas of the application. We do not screen, edit, publish, or review Comments prior to their appearance on the application and Comments do not reflect the views or opinions of us, our agents, or affiliates. Comments reflect the view and opinion of the person who posts such view or opinion. To the extent permitted by applicable laws, we shall not be responsible or liable for the Comments or for any loss cost, liability, damages, or expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this application.</p>
          
          <h4>iFrames</h4>
          <p>Without prior approval and express written permission, you may not create frames around our web pages or use other techniques that alter in any way the visual presentation or appearance of our application.</p>
          
          <h4>Content Liability</h4>
          <p>We shall have no responsibility or liability for any content appearing on your application. You agree to indemnify and defend us against all claims arising out of or based upon your application.</p>
          
          <h4>Reservation of Rights</h4>
          <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our application. You agree to immediately remove all links to our application upon such request.</p>
          
          <h4>Removal of Links from our Application</h4>
          <p>If you find any link on our application or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</p>
          
          <h4>Disclaimer</h4>
          <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our application and the use of this application. Nothing in this disclaimer will:</p>
        </div>
      </Modal.Body>
    </Modal>
        <Container
          className="p-0"
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              letterSpacing: "4px",
              color: "white",
              fontWeight: "600",
            }}
            className={window.innerWidth <= 768 ? "mobile-margin-top" : ""}
          >
            DIRIDO
          </div>

          <Row
            className="no-gutters"
            style={{
              borderRadius: "10px",
              overflow: "auto",
              justifyContent: "center",
              display: "flex",
              margin: "20px",
            }}
          >
            {/* For register */}

            <Col
              md="12"
              className="bg-white pt-2  pb-2"
              style={{ height: "auto", overflowY: "hidden", width: "400px" }}
            >
              <div className="sign-in-from" style={{ paddingInline: "20px" }}>
                <h3 className="mb-0">Sign Up</h3>
                {/* <p>Enter your email address and password to access admin panel.</p> */}
                <Form className="mt-2" onSubmit={(e) => handleSubmit(e)}>
                  <Form.Group className="form-group">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <Form.Label
                        className="mb-0 flex"
                        style={{ width: "50%" }}
                      >
                        First Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Label className="mb-0 flex">
                        Last Name <span style={{ color: "red" }}></span>
                      </Form.Label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="mb-0 flex"
                        id="firstName"
                        placeholder="First Name"
                        required
                      />
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="mb-0 flex"
                        id="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                  </Form.Group>
                  {generate && (
                    <Form.Group className="form-group">
                      <Form.Label>
                        Email address <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mb-0"
                        id="email"
                        placeholder="Enter email"
                        required
                      />
                      {/* {formData.email && (
                                                <button type="button" className="btn btn-link rounded-pill float-end" onClick={handleGenerateOTP}>Send OTP</button>
                                          )}         */}
                    </Form.Group>
                  )}
                  {verify && (
                    <>
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          className="mb-0"
                          id="email"
                          placeholder="Enter email"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Enter OTP</Form.Label>
                        <Form.Control
                          type="text"
                          name="emailOTP"
                          value={formData.emailOTP}
                          onChange={handleChange}
                          className="mb-0"
                          id="emailOTP"
                          placeholder="Enter OTP"
                          required
                        />
                        {/* <button type="button" class="btn btn-link rounded-pill float-end" onClick={handleVerifyOTP}>Verify OTP</button> */}
                      </Form.Group>
                    </>
                  )}
                  {register && (
                    <Form.Group className="form-group">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        className="mb-0"
                        id="email"
                        placeholder="Enter email"
                        disabled
                      />
                      <button
                        type="button"
                        class="btn btn-link rounded-pill float-end text-green"
                      >
                        Verified
                      </button>
                    </Form.Group>
                  )}
                  {register && (
                    <div>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "5px",
                          }}
                        >
                          {/* <Form.Control style={{ width: "30%" }} type="text" name="mobileCode" value={formData.mobileCode} onChange={handleChange} className="mb-0" id="mobileCode" placeholder="Enter Country Code" required/> */}
                          <Form.Select
                            style={{ width: "30%" }}
                            name="mobileCode"
                            value={formData.mobileCode}
                            onChange={handleChange}
                            className="mb-0"
                            id="mobileCode"
                            required
                          >
                            <option value="+91">+91</option>
                            <option value="+92">+92</option>
                          </Form.Select>

                          <Form.Control
                            style={{ width: "70%" }}
                            name="mobile"
                            value={formData.mobile}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  mobile: e.target.value,
                                }));
                              }
                            }}
                            className="mb-0"
                            id="mobile"
                            placeholder="Enter Phone Number"
                            required
                            type="tel"
                            maxLength={10}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Type <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="mb-0 ">
                          <Form.Check
                            style={{ marginRight: "20px" }}
                            type="radio"
                            name="category"
                            value="individual"
                            label="Individual"
                            checked={categorypost === "individual"}
                            onChange={handleCategoryChange}
                            required
                          />
                          <Form.Check
                            type="radio"
                            name="category"
                            value="business"
                            label="Business"
                            checked={categorypost === "business"}
                            onChange={handleCategoryChange}
                            required
                          />
                        </div>
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>
                          Password <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="mb-0"
                          id="password"
                          placeholder="Password (John@209)"
                          required
                        />
                        {passwordError && (
                          <div className="text-danger">{passwordError}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Confirm Password{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          className="mb-0"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          required
                        />
                      </Form.Group>
                    </div>
                  )}
                  <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-1 pt-1">
                      <Form.Check.Input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={tc}
                        onChange={(e) => setTc(e.target.value)}
                        className="me-1"
                        id="agreeToTerms"
                        required
                      />
                      <Form.Check.Label>
                        I accept{" "}
                        <Link onClick={openPopup}>Terms and Conditions</Link>
                      </Form.Check.Label>
                    </Form.Check>
                    {generate && (
                      <Button
                        className="btn-primary float-end"
                        onClick={handleGenerateOTP}
                      >
                        Send OTP
                      </Button>
                    )}

                    {verify && (
                      <Button
                        className="btn-primary float-end"
                        onClick={handleVerifyOTP}
                      >
                        Verify OTP
                      </Button>
                    )}
                    {register && (
                      <Button type="submit" className="btn-primary float-end">
                        Sign Up
                      </Button>
                    )}
                  </div>
                  <div className="sign-info">
                    <span className="dark-color d-inline-block line-height-1">
                      Already Have Account ?{" "}
                      <Link to="/auth/sign-in">Log In</Link>
                    </span>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </>
  );
};

export default SignUp;
