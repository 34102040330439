import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

function VcardTemplate() {
  const { id } = useParams();
  const [data, setData] = useState({});
  let history = useNavigate();

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://dirido-b2c.pb18.in/api/qrc/social-net/scan/${id}`,
      );
      console.log("user details fetched successfully", response.data.data.data.profileImageLink);
      setData(response.data.data.data);
    } catch (error) {
      console.error("Failed Fetching Details", error);
    }
  };

  useEffect(() => {
    fetchUserData(id);
  }, [id]);

  const renderCard = () => {
    switch(data.templateNumber) {
      case "design2":
        return (
          <div
            style={{
              border: "1px solid #d4d4d4",
              margin: "7px",
              borderRadius: "10px",
              background: "rgb(231,238,243)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={data?.profileImageLink ? data?.profileImageLink : '/profile.png'}
              style={{
                display: "flex",
                width: "100px",
                marginInline: "auto",
                borderRadius: "100px",
                border: "3px solid #fff",
                marginTop: "10px",
                position:"relative",
              }}
              alt="Profile"
            />
            <div style={{
              overflow:"hidden",
              borderRadius: "10px",
              paddingBlock: "10px",
              background: "rgb(66,128,231)",
              marginInline:"10px",
              display: "flex",
              paddingTop:"60px",
              marginTop:"-51px",
              flexDirection: "column",
            }}>
              <label style={{ display: "flex", marginInline: "auto", fontSize: "14px", color: "#fff" }}>
                {data.name}
              </label>
              <label style={{ display: "flex", marginInline: "auto", fontSize: "12px", color: "#fff" }}>
                {data.designation}
              </label>
              <label style={{ display: "flex", marginInline: "auto", fontSize: "12px", color: "#fff" }}>
                {data.companyName}
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Description :</div>
              <div style={{ display: "flex" }}>{data.personalDescription}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Email :</div>
              <div style={{ display: "flex" }}>{data.contactInfo?.email}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Phone :</div>
              <div style={{ display: "flex" }}>{data.contactInfo?.phone}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Company :</div>
              <div style={{ display: "flex" }}>{data.companyName}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Position :</div>
              <div style={{ display: "flex" }}>{data.designation}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: "10px",
                marginBottom: "8px",
                marginTop: "8px",
                borderBottom:"1px solid #d4d4d4",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Address :</div>
              <div style={{ display: "flex" }}>
                {data.address?.street}, {data.address?.city}, {data.address?.city}, {data.address?.country} - {data.address?.zipCode}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div
            style={{
              border: "1px solid #d4d4d4",
              margin: "7px",
              borderRadius: "10px",
              background: "rgb(231,238,243)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{
              borderRadius: "10px",
              paddingBlock: "10px",
              background: "rgb(66,128,231)",
              display: "flex",
              flexDirection: "column",
            }}>
              <img
              src={data?.profileImageLink ? data?.profileImageLink : '/profile.png'}
              style={{
                  display: "flex",
                  width: "80px",
                  marginInline: "auto",
                  borderRadius: "100px",
                  border: "3px solid #fff",
                  marginTop: "10px",
                }}
                alt="Profile"
              />
              <label style={{ display: "flex", marginInline: "auto", fontSize: "14px", color: "#fff" }}>
                {data.name}
              </label>
              <label style={{ display: "flex", marginInline: "auto", fontSize: "12px", color: "#fff" }}>
                {data.designation}
              </label>
              <label style={{ display: "flex", marginInline: "auto", fontSize: "12px", color: "#fff" }}>
                {data.companyName}
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                textAlign: "left",
                paddingInline: "10px",
                borderRadius: "5px",
                marginTop: "8px",
                marginInline: "8px",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Description :</div>
              <div style={{ display: "flex" }}>{data.personalDescription}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                paddingInline: "10px",
                borderRadius: "5px",
                marginBottom: "8px",
                marginTop: "8px",
                marginInline: "8px",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Email :</div>
              <div style={{ display: "flex" }}>{data.contactInfo?.email}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                paddingInline: "10px",
                borderRadius: "5px",
                marginBottom: "8px",
                marginInline: "8px",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Phone :</div>
              <div style={{ display: "flex" }}>{data.contactInfo?.phone}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                paddingInline: "10px",
                borderRadius: "5px",
                marginBottom: "8px",
                marginInline: "8px",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Company :</div>
              <div style={{ display: "flex" }}>{data.companyName}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                paddingInline: "10px",
                borderRadius: "5px",
                marginBottom: "8px",
                marginInline: "8px",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Position :</div>
              <div style={{ display: "flex" }}>{data.designation}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                paddingInline: "10px",
                borderRadius: "5px",
                marginInline: "8px",
                marginBottom: "8px",
                textAlign: "left",
              }}
            >
              <div style={{ display: "flex", fontWeight: "600" }}>Address :</div>
              <div style={{ display: "flex" }}>
                {data.address?.street}, {data.address?.city}, {data.address?.city}, {data.address?.country} - {data.address?.zipCode}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Container className="p-0">
        <Row className="no-gutters height-self-center">
          <Col sm="12" className="text-center align-self-center">
            {renderCard()}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VcardTemplate;
