import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Nav,
  Tab,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Modal,
  Carousel as CarouselN,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import axios from "axios";

import Card from "../../../components/Card";
import CustomToggle from "../../../components/dropdowns";
import ShareOffcanvas from "../../../components/share-offcanvas";
import useAuth from "../../../utils/useAuth";
import './Profile.css';

import img1 from "../../../assets/images/page-img/profile-bg1.jpg";
import img5 from "../../../assets/images/icon/10.png";
import user1 from "../../../assets/images/user/1.jpg";
import user05 from "../../../assets/images/user/05.jpg";
import user02 from "../../../assets/images/user/02.jpg";
import user03 from "../../../assets/images/user/03.jpg";
import user06 from "../../../assets/images/user/06.jpg";
import user07 from "../../../assets/images/user/07.jpg";
import user08 from "../../../assets/images/user/08.jpg";
import user09 from "../../../assets/images/user/09.jpg";
import user10 from "../../../assets/images/user/10.jpg";
import icon1 from "../../../assets/images/icon/01.png";
import icon2 from "../../../assets/images/icon/02.png";
import icon3 from "../../../assets/images/icon/03.png";
import icon4 from "../../../assets/images/icon/04.png";
import icon5 from "../../../assets/images/icon/05.png";
import icon6 from "../../../assets/images/icon/06.png";
import icon7 from "../../../assets/images/icon/07.png";
import icon8 from "../../../assets/images/icon/08.png";
import icon9 from "../../../assets/images/icon/09.png";
import icon10 from "../../../assets/images/icon/10.png";
import icon11 from "../../../assets/images/icon/11.png";
import icon12 from "../../../assets/images/icon/12.png";
import icon13 from "../../../assets/images/icon/13.png";
import g1 from "../../../assets/images/page-img/g1.jpg";
import g2 from "../../../assets/images/page-img/g2.jpg";
import g3 from "../../../assets/images/page-img/g3.jpg";
import g4 from "../../../assets/images/page-img/g4.jpg";
import g5 from "../../../assets/images/page-img/g5.jpg";
import g6 from "../../../assets/images/page-img/g6.jpg";
import g7 from "../../../assets/images/page-img/g7.jpg";
import g8 from "../../../assets/images/page-img/g8.jpg";
import g9 from "../../../assets/images/page-img/g9.jpg";
import small07 from "../../../assets/images/small/07.png";
import small08 from "../../../assets/images/small/08.png";
import small09 from "../../../assets/images/small/09.png";
import small1 from "../../../assets/images/small/07.png";
import small2 from "../../../assets/images/small/08.png";
import small3 from "../../../assets/images/small/09.png";
import small4 from "../../../assets/images/small/10.png";
import small5 from "../../../assets/images/small/11.png";
import small6 from "../../../assets/images/small/12.png";
import small7 from "../../../assets/images/small/13.png";
import small8 from "../../../assets/images/small/14.png";
import user9 from "../../../assets/images/user/1.jpg";
import img59 from "../../../assets/images/page-img/59.jpg";
import img51 from "../../../assets/images/page-img/51.jpg";
import img52 from "../../../assets/images/page-img/52.jpg";
import img53 from "../../../assets/images/page-img/53.jpg";
import img54 from "../../../assets/images/page-img/54.jpg";
import img55 from "../../../assets/images/page-img/55.jpg";
import img56 from "../../../assets/images/page-img/56.jpg";
import img57 from "../../../assets/images/page-img/57.jpg";
import img58 from "../../../assets/images/page-img/58.jpg";
// import img59 from '../../../assets/images/page-img/59.jpg'
import img60 from "../../../assets/images/page-img/60.jpg";
import img61 from "../../../assets/images/page-img/61.jpg";
import img62 from "../../../assets/images/page-img/62.jpg";
import img64 from "../../../assets/images/page-img/64.jpg";
import img65 from "../../../assets/images/page-img/65.jpg";
import img63 from "../../../assets/images/page-img/63.jpg";
import img2 from "../../../assets/images/user/11.png";
import img3 from "../../../assets/images/icon/08.png";
import img4 from "../../../assets/images/icon/09.png";
// import img5 from '../../../assets/images/icon/10.png'
import img6 from "../../../assets/images/icon/11.png";
import img7 from "../../../assets/images/icon/12.png";
import img8 from "../../../assets/images/icon/13.png";
import img9 from "../../../assets/images/page-img/07.jpg";
import img10 from "../../../assets/images/page-img/06.jpg";
import p1 from "../../../assets/images/page-img/p1.jpg";
import p3 from "../../../assets/images/page-img/p3.jpg";
// import user1 from '../../../assets/images/user/1.jpg'
// import user05 from '../../../assets/images/user/05.jpg'
import user01 from "../../../assets/images/user/01.jpg";
// import user02 from '../../../assets/images/user/02.jpg'
// import user03 from '../../../assets/images/user/03.jpg'
// import user06 from '../../../assets/images/user/06.jpg'
// import user07 from '../../../assets/images/user/07.jpg'
// import user08 from '../../../assets/images/user/08.jpg'
// import user09 from '../../../assets/images/user/09.jpg'
// import user10 from '../../../assets/images/user/10.jpg'
import user13 from "../../../assets/images/user/13.jpg";
import user14 from "../../../assets/images/user/14.jpg";
import user15 from "../../../assets/images/user/15.jpg";
import user16 from "../../../assets/images/user/16.jpg";
import user17 from "../../../assets/images/user/17.jpg";
import user18 from "../../../assets/images/user/18.jpg";
import user19 from "../../../assets/images/user/19.jpg";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import { useNavigate } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import ProfileTab from "./profiletab";
// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const Profile1 = () => {
  useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [userid, setUserid] = useState("");
  const [userUid, setUserUid] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(
      //   "user details fetched successfully user1",
      //   response?.data?.data
      // );
      setUserData(response?.data?.data);
      setUserid(response?.data?.data._id);
      setUserUid(response?.data?.data.UID);
      setLoading(false);
    } catch (error) {
      console.error("Failed Fetching Details", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userid]);
  //    const {userid} = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [post, setPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleAllPost = async (updateData = false) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/post/all?page=${page}&limit=5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Post Fetched Successfully", response.data.data.allPosts);
      // setPost(response.data.data.allPosts);
      // setHasMore(response.data.data.allPosts.length > 0);
      if (updateData) {
        setPost(response.data.data.allPosts);
      } else {
        setPost((prevPost) => [...prevPost, ...response.data.data.allPosts]);
      }
      setHasMore(response.data.data.allPosts.length > 0);
    } catch (error) {
      console.log("Failed Fetching Posts", error);
    }
  };

  useEffect(() => {
    handleAllPost(true);
  }, [page, userid]);

  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpand = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };
  const renderDescription = (data, index) => {
    const description = data?.description;
    const threshold = 180;

    if (description && description.length > threshold) {
      if (expandedRows[index]) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "#FF5500",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "block",
              }}
            >
              Show Less...
            </button>
          </div>
        );
      } else {
        return (
          <div>
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: `${description.slice(0, threshold)}...`,
              }}
            />
            <button
              onClick={() => toggleExpand(index)}
              style={{
                color: "#FF5500",
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "inline",
              }}
            >
              Show More...
            </button>
          </div>
        );
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: description }} />;
    }
  };
  const profileImage = localStorage.getItem("profileImage")
    ? localStorage.getItem("profileImage")
    : "/profile.png";

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  const [userDetail, setUserDetail] = useState("");
  const handleUserDetail = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/scan-profile/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("User Detail fetched successfully ", response.data.data.noOfConnection);
      setUserDetail(response.data.data);
    } catch (error) {
      console.log("Failed Fetching User Detail", error);
    }
  };

  useEffect(() => {
    handleUserDetail();
  }, [userid]);

  const handleSendRequest = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/connection/sent-request`,
        {
          requestTo: userid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);
      // console.log("Request Send successfully");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Failed Sending Friend Request", error);
    }
  };

  const handleSavePost = async (postID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/post/save/${postID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data"
          },
        }
      );
      // console.log("Post Saved", response.data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed Saving Post");
      console.log("Failed Saving Posts", error);
    }
  };

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const [qrImageUrl, setQrImageUrl] = useState("");
  const handleQrClick = (imageUrl) => {
    setQrImageUrl(imageUrl);
    setQrPopupOpen(true);
  };

  const closeQrPopup = () => {
    setQrPopupOpen(false);
  };

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportDescription, setReportDescription] = useState("");
  const [reportId, setReportId] = useState("");
  const handleReportClick = (reqid) => {
    setReportId(reqid);
    setReportModalOpen(true);
  };

  const closeReportModal = () => {
    setReportModalOpen(false);
  };
  const handleReport = async () => {
    if (!reportDescription) {
      toast.info("Report issue is required");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/report`,
        {
          reportFor: "post",
          issueDescription: reportDescription,
          userId: "",
          postId: reportId,
          eventId: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      closeReportModal();
      setReportId("");
      setReportDescription("");
      // console.log("Post Reported", response.data);
      toast.success(response.data.message);
    } catch (error) {
      setReportId("");
      setReportDescription("");
      toast.error(error?.response?.data?.message || "Failed Reporting Post");
      console.log("Failed Reporting Posts", error);
    }
  };

  const [connection, setConnection] = useState([]);

  const handleConnections = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/connection/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Connections Fetched Successfully");
      setConnection(response.data.data);
    } catch (error) {
      console.log("Failed Fetching Posts", error);
    }
  };
  useEffect(() => {
    handleConnections();
  }, []);

  const handleNavigateProfile = (userid) => {
    navigate(`/userprofile/${userid}`);
  };
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileInputChange = (event) => {
    setSelectedFile([...event.target.files]);
    toast.success("File selected!");
  };
  const [categorypost, setCategorypost] = useState("public");

  const handleCategoryChange = (e) => {
    setCategorypost(e.target.value);
  };

  const [photos, setPhotos] = useState([]);
  const handlePost = async () => {
    if (!description) {
      toast.info("Description is required");
      return;
    }
    try {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/file/upload/multiple`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log("oath", uploadResponse.data.data);

      setPhotos(uploadResponse.data.data);
      postPhoto(uploadResponse.data.data);

      //   setShow(false);
    } catch (error) {
      console.log("Failed to create post:", error);
      toast.error(
        error?.uploadResponse?.data?.message || "Failed to Upload Image/Video"
      );
    }
  };

  const postPhoto = async (photoss) => {
    if (!description) {
      toast.info("Description is required");
      return;
    }
    try {
      const postData = {
        description: description,
        photos: photoss,
        postType: categorypost,
      };

      const postResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/post`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      handleAllPost(true);
      // console.log("Post Successfully", postResponse.data);
      toast.success("Post created successfully");
      setDescription("");
      setSelectedFile([]);
      //   setShow(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("Failed Posting ", error);
    } finally {
      setShow(false);
    }
  };
  const [userDetailSocial, setUserDetailSocial] = useState("");
  const handleUserDetailSocial = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/social-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(
      //   "User Detail fetched successfully social",
      //   response.data.data.userData
      // );
      let detail = response.data.data.userData;
      setEditForm({
        userBio: detail?.userProfileData?.userBio,
        city: detail?.userProfileData?.address?.city,
        country: detail?.userProfileData?.address?.country,
        birthday: detail?.userProfileData?.birthday,
      });
      setUserDetailSocial(detail);
    } catch (error) {
      console.log("Failed Fetching User Detail", error);
    }
  };

  useEffect(() => {
    handleUserDetailSocial();
  }, []);

  const [profileInfo, setProfileInfo] = useState(true);
  const [edit, setEdit] = useState(false);
  const [editForm, setEditForm] = useState({
    userBio: "",
    city: "",
    country: "",
    birthday: "",
  });
  const openEdit = () => {
    setProfileInfo(false);
    setEdit(true);
  };

  const handleSave = async () => {
    // console.log(editForm);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/user/social-profile`,
        editForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleUserDetailSocial();
      setProfileInfo(true);
      setEdit(false);
    } catch (error) {
      console.error("Failed Fetching Details", error);
    }
  };

  const handleEditFormChange = (e) => {
    setEditForm({ ...editForm, [e.target.name]: e.target.value });
  };
  const [coverImage, setCoverImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    // console.log("fileInputRef", fileInputRef);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCoverImage(file);
  };
  // console.log("image",coverImage)
  const handleEditCover = async () => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", coverImage);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/file/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const uploadProfile = await axios.put(
        `${process.env.REACT_APP_URL}/api/user/social-profile`,
        {
          backgroundImageUrl: response.data.data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      toast.success("Cover Image Updated");
      handleUserDetailSocial();
      // setProfileInfo(true);
      // setEdit(false);
    } catch (error) {
      console.error("Failed Fetching Details", error);
    }
  };
  useEffect(() => {
    if (coverImage) {
      handleEditCover();
    }
  }, [coverImage]);

  if (loading) {
    return (
      <div className="loader-container">
        <img src={loader} alt="Loading..." />
      </div>
    );
  }



  return (
    <>
      <ToastContainer />
      <FsLightbox
        toggler={imageController.toggler}
        sources={[
          g1,
          g2,
          g3,
          g4,
          g5,
          g6,
          g7,
          g8,
          g9,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img59,
          img60,
          img61,
          img62,
          img63,
          img64,
          img65,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img59,
          img60,
        ]}
        slide={imageController.slide}
      />
      <Container>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body className=" profile-page p-0">
                <div className="profile-header">
                  <div className="position-relative">
                    <img
                      loading="lazy"
                      src={
                        userDetailSocial?.userProfileData?.backgroundImageUrl
                          ? userDetailSocial?.userProfileData
                              ?.backgroundImageUrl
                          : img1
                      }
                      alt="profile-bg"
                      style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover",
                      }}
                      className="rounded img-fluid"
                    />
                    <ul
                      className="header-nav list-inline d-flex flex-wrap p-0 m-0"
                      style={{
                        width: "100%",
                        zIndex: "999",
                        justifyContent: "space-between",
                        paddingLeft: "15px",
                        alignItems: "end",
                      }}
                    >
                      <li className="qr-code-li largescreen"
                      >
                        <img
                          src={userDetailSocial?.qrCodeURL}
                          alt="QR"
                          onClick={() =>
                            handleQrClick(userDetailSocial?.qrCodeURL)
                          }
                          style={{
                            width: "130px",
                            height: "130px",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                        <div
                          style={{ textAlign: "center", marginTop: "-10px" }}
                        >
                          {userDetailSocial?.UID}
                        </div>
                      </li>
                      <li
                        style={{
                          marginLeft: "15px",
                          position: "absolute",
                          right: "10px",
                          bottom: "0px",
                          zIndex: "9999",
                        }}
                      >
                        <a
                          href="#"
                          onClick={handleClick}
                          className="material-symbols-outlined"
                        >
                          edit
                        </a>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="user-detail text-center mb-3">
                    <div className="profile-img">
                      <img
                        loading="lazy"
                        src={
                          !userData?.profileImageLink
                            ? "/profile.png"
                            : userData?.profileImageLink
                        }
                        alt="profile-img"
                        className="avatar-130 img-fluid"
                        style={{ width: "120px", height: "120px" }}
                      />{" "}
                    </div>
                    <div className="profile-detail">
                      <div style={{ fontSize: "20px" }}>
                        {userData?.firstName} {userData?.lastName}{" "}
                        <span
                          style={{
                            textTransform: "capitalize",
                            fontSize: "16px",
                          }}
                        >
                          ({userData?.clientUseFor})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                    <div className="social-links">
                      <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                        {/* <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img3} className="img-fluid rounded" alt="facebook"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img4} className="img-fluid rounded" alt="Twitter"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img5} className="img-fluid rounded" alt="Instagram"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img6} className="img-fluid rounded" alt="Google plus"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img7} className="img-fluid rounded" alt="You tube"/></Link>
                                    </li>
                                    <li className="text-center md-pe-3 pe-0">
                                       <Link to="#"><img loading="lazy" src={img8} className="img-fluid rounded" alt="linkedin"/></Link>
                                    </li> */}
                        <li className="text-center pe-3">
                          <h6>Posts</h6>
                          <p className="mb-0">{userDetailSocial?.noOfPosts ? userDetailSocial?.noOfPosts : "0"}</p>
                        </li>
                        
                        <li className="text-center pe-3">
                          <h6>Connections</h6>
                          <p className="mb-0">{userDetailSocial?.noOfConnection ? userDetailSocial?.noOfConnection : "0"}</p>
                        </li>
                        
                      </ul>
                    </div>
                    <div className="social-info">
                      <ul
                        className="social-data-block d-flex align-items-center justify-content-center list-inline p-0 m-0"
                        style={{ justifyContent: "center" }}
                      >
                        <li className="text-center ">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <button  className="btn btn-primary d-block " style={{display:"flex",width:"200px" }}  onClick={handleSendRequest}>Send Request</button> */}
                          </div>
                        </li>
                        <li className="text-center pe-3">
                        <h6 className="mb-0" style={{ display: "flex" }}>
                            {" "}
                            <span className="material-symbols-outlined md-18">
                              call
                            </span>
                            <p className="mb-0 ms-2">{userData?.mobile}</p>
                          </h6>
                          <h6 style={{ display: "flex" }}>
                            {" "}
                            <span className="material-symbols-outlined md-18">
                              email
                            </span>
                            <p className="mb-0 ms-2">{userData?.email}</p>
                          </h6>
                         
                        </li>
                        
                        {/* <li className="text-center pe-3">
                                       <h6>Following</h6>
                                       <p className="mb-0">100</p>
                                    </li> */}
                      </ul>
                      <div className="qr-code-li1 largescreen1"
                        //   style={{
                        //   marginLeft: "15px",
                        //   background: "#fff",
                        //   borderRadius: "10px",
                        //   justifyContent:"center",
                        //   display:"flex",
                        //   flexDirection:"column",
                        // }} 
                      >
                        <img
                          src={userDetailSocial?.qrCodeURL}
                          alt="QR"
                          onClick={() =>
                            handleQrClick(userDetailSocial?.qrCodeURL)
                          }
                          style={{
                            width: "130px",
                            height: "130px",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            display:"flex",
                            marginInline:"auto",
                          }}
                        />
                        <div
                          style={{ textAlign: "center", marginTop: "-10px" }}
                        >
                          {userDetailSocial?.UID}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ProfileTab userUid={userUid}/>
        </Row>
        <Modal
          size="sm"
          style={{ zIndex: "99999" }}
          className="fade"
          id="qr-modal"
          onHide={closeQrPopup}
          show={qrPopupOpen}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img
              src={qrImageUrl}
              alt="QR Code"
              style={{ width: "200px", height: "auto" }}
            />
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginTop: "-10px",
              }}
            >
              {userDetailSocial?.UID}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <a
              href={qrImageUrl}
              download="qr-code.png"
              className="btn btn-primary"
            >
              Download
            </a>
            <button className="btn btn-secondary" onClick={closeQrPopup}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default Profile1;
