import { useEffect, useState } from "react";
import { Row, Col, Container, Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CustomToggle from "../../components/dropdowns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IoMdArrowRoundForward } from "react-icons/io";

//image
import img1 from "../../assets/images/small/07.png";
import img2 from "../../assets/images/small/08.png";
import img3 from "../../assets/images/small/09.png";
import s5 from "../../assets/images/page-img/s5.jpg";
import img42 from "../../assets/images/page-img/42.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import moment from "moment";
import PostCard from "./Post/PostCard";
import useAuth from "../../utils/useAuth";

const Sponsored = () => {
  useAuth();
  const navigate = useNavigate();

  const [sponsoredAd, setSponsoredAd] = useState([]);
  const handleSponsored = async () => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
            `${process.env.REACT_APP_URL}/api/ad/all/ads`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("Sponsored Ads Fetched Successfully");

        const adData = response.data.data.adData;
        const activeAd = adData.find(ad => ad.status === "Active");

        if (activeAd) {
            setSponsoredAd(activeAd);
        } else {
            console.log("No Active Sponsored Ads Found");
        }
    } catch (error) {
        console.log("Failed Fetching Sponsored Ads", error);
    }
};


  useEffect(() => {
    handleSponsored();
  }, []);

  return (
    <>
      <Card>
        {/* <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Sponsored</h4>
          </div>
          
        </div> */}

        <Card.Body>
          <ul className="suggested-page-story m-0 p-0 list-inline">
            <li className="mb-1">
              <div className="d-flex align-items-center mb-1">
                {/* <img
                  src={img42}
                  alt="story-img"
                  className="rounded-circle img-fluid avatar-50"
                /> */}
                <div className="stories-data ms-3">
                  <h5 style={{color:"gray"}}>{sponsoredAd?.title} (Sponsored)</h5>
                  {/* <p className="mb-0">Lorem Ipsum</p> */}
                </div>
              </div>
              {/* <img src={img9} className="img-fluid rounded" alt="Responsive"/> */}
              <Carousel
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay
                interval={2000}
              >
                {sponsoredAd?.image?.map((image) => (
                  <div>
                    <img
                      src={image}
                      className="img-fluid rounded"
                      alt="Responsive"
                    />
                  </div>
                ))}
                {/* <div>
                                                    <img src={img10} className="img-fluid rounded" alt="Responsive" />
                                                </div> */}
              </Carousel>
            </li>
            {/* <Link to="/advertisements" className="btn btn-primary d-block mt-3">
              See All
            </Link> */}
            <Link to="/advertisements" className="text-primary" style={{display:"flex", fontWeight:"600", justifyContent:"right", alignItems:"center"}}>View all<IoMdArrowRoundForward />
</Link>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

export default Sponsored;
